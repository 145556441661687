import { defineStore }  from 'pinia'
import axios from 'axios'
import { getAuthToken, getUserInfo, logoutUser } from './../helpers/auth.js'
import ArklyStyle from '@/style/_exports.module.scss'
import { event } from 'vue-gtag'
import { createDirectus } from '@directus/sdk'
import { rest, readItems } from '@directus/sdk/rest'
import json from "@/assets/data/mapboxLookupDict.json";
const controller = new AbortController()

const directus = createDirectus('https://cms.hightide.ai/').with(rest())
//import BackupTranslations from './../assets/data/backup_translations.json'
import BackupTranslationsBrand from './../assets/data/backup_translations_brand.json'
import BackupTranslationsFooter from './../assets/data/backup_translations_footer.json'
import BackupTranslationsButtons from './../assets/data/backup_translations_buttons.json'
import BackupTranslationsHomepage from './../assets/data/backup_translations_homepage.json'
import BackupTranslationsInsurance from './../assets/data/backup_translations_insurance.json'
import BackupTranslationsMitigation from './../assets/data/backup_translations_mitigation.json'
import BackupTranslationsPropertyView from './../assets/data/backup_translations_property_view.json'
import BackupTranslationsRocketflood from './../assets/data/backup_translations_rocketflood.json'
import BackupTranslationsTutorial from './../assets/data/backup_translations_tutorial.json'
import listOfIPs from './../assets/data/IP_addresses.json'

let userLanguage = 'en-US'
if (navigator.language == 'en-US' || navigator.language == 'es-ES') {
	userLanguage = navigator.language
}

const MAPBOX_KEY = process.env.VUE_APP_MAPBOX_KEY ? process.env.VUE_APP_MAPBOX_KEY : 'pk.eyJ1IjoiaGlnaHRpZGVpbnRlbGxpZ2VuY2UiLCJhIjoiY2xvZWh2bGxuMDJxYTJxbDVteHY2Y3YzdyJ9.DhndazHSrQFF_lFq2fjUCg'
const AD_TAG = process.env.VUE_APP_ADS_TAG ? process.env.VUE_APP_ADS_TAG : 'AW-11279096941/vkNdCJi6wcsYEO24pYIq'

export const useConfigStore = defineStore('config', {
	state: () => ({
		geojson: {
			'type': 'FeatureCollection',
			'features': []
		},
		goToPRModal: false,
		CA_SaveStatus: undefined,
		addedPoints: [],
		CA_Actions: [],
		removedIDs: [],
		polyInteraction: false,
		lineInteraction: false,
		mapboxLookupDict: json,
		govSubName: 'Arkly Pro',
		zoomedIn: true,
		draw: undefined,
		AdsConversionGtagID: AD_TAG,
		userIP: undefined,
		userLanguage: userLanguage,
		mongoCollections:{
			ArklyProperties: 'properties_v3-1',
		},
		cmsCollections: [
			'footer',
			'brand',
			'homepage',
			'buttons',
			'property_view',
			'tutorial',
			'rocket_flood',
			'insurance',
			'mitigation',
			'VulnerabilityReport',
		],
		text: {
			'es-ES': {}, //{...BackupTranslations['es-ES']},
			'en-US': {}, //{...BackupTranslations['en-US']}
		},
		textContent: {}, //{...BackupTranslations['en-US']},
		propertyData: [],
		colors: ArklyStyle
		/*{
			primary: '#4E84C4',
			darkBlue: '#050F29',
			sand: '#F2E9D0',
			grey: '#4a4a4a',
			lightGrey: '#b5b5b5',
			white: '#f2f2f2',
			blue: '#7084EC',
			red: '#D34D45',
			yellow: '#D2B360',
			green: '#588139',
		}*/,
		bodyFont: {
			family: 'New Order',
			color: '#4a4a4a',
			size: 14
		},
		//url: 'http://localhost:8000', 
		url: 'https://dev-api.hightide.ai',
		//url: 'https://api.hightide.ai',
		controls: {
			year: '2020',
			storm: '100 year',
			dataSource: 'NOAA2022',
			units: '$',
			useType: 'structure',
			elevation: 0,
			isFloodZoneVisible: true,
			drawMode: false,
			drawModeName: 'None',
			baselayer: 'Mapbox',
			timeLineUnit: 'year'
		},
		settings: {
			years: [2020, 2030, 2040, 2050, 2060, 2070, 2080, 2090, 2100],
			yearFootDict: {
				'FL': {2020: 0.3, 2030: 0.5, 2040: 0.9, 2050: 1.2, 2060: 1.8, 2070: 2.5, 2080: 3.3, 2090: 4.2, 2100: 5.2, 2110: 6.2, 2120: 6.9},
				'PR': {2020: 0.2, 2030: 0.4, 2040: 0.7, 2050: 1.1, 2060: 1.6, 2070: 2.3, 2080: 3.1, 2090: 4.0, 2100: 5.0, 2110: 5.9, 2120: 6.7},
			},
			footprintActions: ['Submit','Delete Last Drawn','Delete All'],
			sumStatsActions: ['Calculate','Delete Selected','Delete All'],
			storm: ['5 year', '10 year', '25 year', '50 year', '100 year'],
			dataSource: ['NOAA2022', 'NOAA2017', /*'USACE', 'NOAAA2017'*/],
			units: ['$', '$/sqft'],
		},
		equitySettings: {
			years: [2020, 2030, 2040, 2050,/* 2060, 2070, 2080, 2090, 2100*/],
			storm: ['5 year', '10 year', '25 year', '50 year', '100 year'],
			dataSource: ['NOAA2022', 'NOAA2017', /*'USACE', */],
			units: undefined,
		},
		navbarConfig: {
			height: null,
		},
		topbarConfig: {
			height: null,
		},
		sidePanel: {
			isVisible: true,
			// Sidepanel Home takes the form [route name]-home
			currentView: 'arkly-home',
		},
		isRouteLocation: undefined,
		isSidePanelVisible: false,
		isNavMenuVisible: false,
		isPuertoRico: false,
		isDevOrLocal: false,
		streetViewConfig: {
			apiKey: 'AIzaSyCoKBgHtnspHAby1-nskQkHTEqnlBL-Eq8',
		},
		mapConfig: {
			mapPoints: new Array(),
			lastMapPoints: undefined,
			blockgroupGovSubIdField: 'id',
			countyGovSubIdField: 'id',
			equityGovSubIdField: 'blockgroupId',
			clickedID: undefined,
			lastClickedLayer: undefined,
			homepageLayers: ['fl-properties-reduced-v3','properties-pr-reduced'],
			layerToInsertUnder: "waterway-shadow copy",
			critAssetType: 'none',
			arklyRoutes: ['arkly','puertoRico'],
			govSubRoutes: ['economic'],
			routesWithInsurance: ['arkly'],
			routesWithMitigation: ['arkly'],
			currentLayer: 'expectedLoss_NOAA2022_100year_2020_mean',
			lastYearVariableName: 'expectedLoss_NOAA2022_100year_2100_mean',
			layers: {},
			propertySources: ['properties-florida-Arkly-v3-4','properties-puertorico-Arkly-v1-4'],
			govSubLayers: {
				floodZone: ['floodzones-v4'],
				property: ['properties-florida-govSub-v3-3','properties-florida-FZs-v3-4'], //'properties-govsub-v3-1'],
				//aggregate: ['aggregate'],
				county: ['counties-florida-FZs-v3-4','counties-florida-govSub-v3-3'],
				blockgroup: ['bgs-florida-FZs-v3-4','bgs-florida-govSub-v3-3'], //'blockgroup'], blockgroups-govSub-v3 is the combined one
				equity: ['equity-v3-1'],
				criticalAssets: ['CAPoly_florida_v7','CAL_florida_v7','CAP_florida_v7','CAP_LOC_florida_v3'], 
			},
			arklyLayers: {
				floodZone: ['floodzones-v4', 'floodzones-puertorico-v1'],
				property: ['properties-florida-Arkly-v3-4', 'properties-puertorico-Arkly-v1-4','properties-florida-FZs-v3-4'],
				county: ['counties-florida-FZs-v3-4','counties-florida-Arkly-v3-4', 'counties-puertorico-Arkly-v1-4'],
				blockgroup: ['bgs-florida-FZs-v3-4','bgs-florida-Arkly-v3-4', 'bgs-puertorico-Arkly-v1-4'],
			},
			zoomOverRide: undefined,
			layerSettings: {
				property: {
					idField: '_id',
					startingZoom: 14.5,
					zoomRange: [13, 22],
					colorScaleFactor: 1,
					opacity: {
						FL: ['match', ['get', 'afV'], 0, 0.3, 1],
						PR: ['match', ['get', 'damageInLastYear'], 0, 0.3, 1],
					},
					//opacityFZs: ["case", ["in",["get", "floodZone"],"X"],0.3,["in",["get", "floodZone"],""],0.3,1]
					opacityFZs: ['match', ['get', 'EL'], 0, 0.3, 1],
				},
				blockgroup: {
					idField: 'key',
					startingZoom: 10,
					zoomRange: [8, 14],
					colorScaleFactor: 100,
					opacity: ["interpolate",["linear"],["zoom"],8,0,8.2,0.5,12,0.5,14,0],
				},
				county: {
					idField: '_id',
					startingZoom: 7.5,
					zoomRange: [0, 8.2],
					colorScaleFactor: 10000,
					opacity: ["interpolate",["linear"],["zoom"],6,0.9,8.2,0.21],
				},
				aggregate: {
					idField: '_id',
					startingZoom: 10,
					zoomRange: [0, 14],
					colorScaleFactor: 100,
					opacity: ["interpolate",["linear"],["zoom"],8.2,0.5,12,0.5,14,0],
				},
				equity: {
					idField: 'blockgroupId',
					startingZoom: 10,
					zoomRange: [0, 22],
					colorScaleFactor: 1,
					opacity: 0.5,
				},
				floodZone: {
					opacity: 0.5,
					fillColor: "#7084ec",
				},
				criticalAssets: {
					circleColor: '#ffffff',
					circleRadius: 3,
					circleBorderColor: '#000000',
					circleBorderLocalColor: '#FFFFFF',
					circleBorderWidth: 0.75,
					opacity: ['case',['feature-state', 'qced'],0,0.7],
					//color: ['match',['get', 'CA_Match_Type'],1,'#00FF80',2,'#FFFF00',3,'#FF0000',/* other */ '#000000'],
					//color: ['match',['get', 'CA_Surf'],0,'#00FF80',1,'#FF0000',2,'#FFA500',/* other */ '#000000'],
					color: ['case',['==',['feature-state', 'included'],true],'#00bfff',['==',['get', 'CA_Surf'],0],'#00FF80',['==',['get', 'CA_Surf'],1],'#FF0000',['==',['get', 'CA_Surf'],2],'#FFA500','#FFFFFF'],
					fillColor: ['case',['==',['feature-state', 'included'],true],'#00bfff','#00FF80'],
					fillOutlineColor: "#000000",
					opacityPoly: ['case',['feature-state', 'qced'],0,0.5],
					lineWidth: ['case',['feature-state', 'qced'],0,2],
					zoomRange: [0, 22],
				},
			},
			searchOptions: [],
			fill: {
				equity: {
					opacity: 0.5,
					steps: [10, 50, 100, 500, 1000],
				},
			},
			dimensions: {
				height: undefined,
				width: undefined,
			},
			isEquityVisible: undefined,
			latLongBoundPadding: 0.2,
			popupDelay: 500,
			popupDelayRemove: 500,
			style: undefined, 
			arklyStyle: 'mapbox://styles/hightideintelligence/clrbbieic002p01oo8qcf987b?optimize=false',
			govSubStyle: 'mapbox://styles/hightideintelligence/clrbbieic002p01oo8qcf987b?optimize=false',//'mapbox://styles/hightideintelligence/clnxsiu5i007201o3fb9zduga?optimize=false', //replaced with v2, v1 govsub: clerfc7qy008301oaiuuoo34s
			accessToken: MAPBOX_KEY,
			map: undefined,
		},
		paymentConfig: {
			checkoutURL: '/payment/create-checkout-session',
			prices: {
				'mitigation-expert': 'price_1MpEBvEhAicvONNulp5MQo9r',
				'risk-expert': 'price_1MpEdtEhAicvONNuZlRTM1nr',
			}
		},
		componentRefs:{},
		tutorial: {
			isVisible: true,
		},
		windowDimensions: {
			width: window.innerWidth,
			height: window.innerHeight,
		},
	}),
	getters: {
		cmsContent(keys) {
			
		},
		screenSize() {
			if (this.windowDimensions.width < 800) {
				return 'mobile'
			} else if (this.windowDimensions.width < 900) {
				return 'tablet'
			} else {
				return 'desktop'
			} 
		},
		changedBaselayer(){
			return this.controls.baselayer
			console.log(this.controls.baselayer)
		},
		isMobile() {
			return this.screenSize == 'mobile'
		},
		isTutorialVisible() {
			return this.tutorial.isVisible
		},
		indexOfCurrentYear() {
			return this.settings.years.indexOf(parseInt(this.controls.year))	
		},
		mapSearchOptions() {
			return Array.from(this.mapConfig.searchOptions)
				//.map(data => data[key as keyof CommonData])
		},
		mapWidth() {
			return this.mapConfig.dimensions.width
		},
		windowHeight() {
			return this.windowDimensions.height
		},	
		endpointURL() {
			if (this.$route.name == 'arkly' || this.$route.name == 'puertoRico') {
				return `${this.url}/surf/deterministic/property/${this.$route.params.id}`
			} else {
				if (this.$route.params.type == 'property') {
					return `${this.url}/surf/deterministic/${this.$route.params.type}/${this.$route.params.id}`
				} else {
					return `${this.url}/surf/aggregate/${this.$route.params.type}/${this.$route.params.id}`
				}
			}
		},
		currentSettings() {
			if (this.$route.params.view == 'socioeconomic' || this.isEquityVisible) {
				return this.equitySettings
			} else {
				return this.settings
			}
		},
		currentStorm() {
			return this.controls.storm.replace(' ', '')
		},
		elevation() {
			return this.controls.elevation
		},
		user() {
			const user = getUserInfo()
			if (user) {
				return user 
			} else {
				return undefined
			}
		},
	},
	actions: {
		/////////////////////////////////////////////////////////////////
		// Critical Asset Actions
		/////////////////////////////////////////////////////////////////
		updateAddedPoints(){
			this.geojson.features = []
			var idsToSkip = []
			this.CA_Actions.forEach(action => {
				if (action[0] == 'removeJoinedAsset') {
					idsToSkip.push(action[1][0])
				}
			})
			this.addedPoints.forEach(point => {
				if (!idsToSkip.includes(point[6])){
					this.geojson.features.push({
							'id': point[6],
							'type': 'Feature',
							'geometry': {
								'type': 'Point',
								'coordinates': [point[0],point[1]]
							},
							'properties': {
								'index': point[6],
								'CA_Name': point[2],
								'CA_Source': point[3],
								'CA_Source_w_Parcel': point[4] + '_' + point[3],
								'CA_class': point[5],
								'CA_ID': point[8],
								'city_name': point[9].split('|')[0],
								'full_fips': point[9].split('|')[1],
							}
						})
				}
			})
			this.mapConfig.map.getSource('point').setData(this.geojson);

		},
		addCA_Action(action,id){
			this.CA_Actions.push([action,id])
			if (action == 'remove') {
				var layerClicked
				this.mapConfig.layers.criticalAssets.forEach(layer => {
					if (layer == id[2]) {
						layerClicked = layer
					}
				})
				this.mapConfig.map.setFeatureState({
					source: layerClicked,
					sourceLayer: layerClicked,
					id: id[0],
					}, {qced: true
				});
				this.removedIDs.push(id[0])
			} else if (action == 'includeAsset') {
				var layerClicked
				this.mapConfig.layers.criticalAssets.forEach(layer => {
					if (layer == id[2]) {
						layerClicked = layer
					}
				})
				this.mapConfig.map.setFeatureState({
					source: layerClicked,
					sourceLayer: layerClicked,
					id: id[0],
					}, {included: true
				});
			} else if (action == 'joinToBuilding') {
				if (id[9].includes('-local-')) {
					var sourceNameToUse = this.mapConfig.layers.criticalAssets.slice(-1)[0]
				} else {
					var sourceNameToUse = this.mapConfig.layers.criticalAssets.slice(-2)[0]
				}
				this.mapConfig.map.setFeatureState({
					source: sourceNameToUse,
					sourceLayer: sourceNameToUse,
					id: id[0],
					}, {qced: true
					});
				this.addedPoints.push([id[2],id[3],id[4],id[5],id[6],id[7],id[0],id[8],id[9],id[10]])
				this.updateAddedPoints()
				this.removedIDs.push(id[0])
			} else if (action == 'removeJoinedAsset') {
				this.updateAddedPoints()
			}
		},
		saveCA_Action(){
			this.CA_SaveStatus = undefined
			const instance = axios.create()

			let url = `${this.url}/auth/save-ca-work`	

			var data = new FormData()
			//console.log(JSON.stringify(this.CA_Actions))
			//console.log(this.addedPoints)
			data.append('history', JSON.stringify(this.CA_Actions))
			data.append('addedPoints', JSON.stringify(this.addedPoints))

			return	instance.post(
				url, data, {
					signal: controller.signal,
					headers: {
						"Content-Type": "multipart/form-data",
						"Authorization": getAuthToken()
					},	
				},
			).then(response => {
				if (response.data.status) {
					this.CA_SaveStatus = true
				} else {
					this.CA_SaveStatus = false
				}
			})
		},
		loadCA_Action(){
			const instance = axios.create()

			let url = `${this.url}/auth/load-ca-work`	
			return	instance.get(
				url, {
					headers: {
						"Content-Type": "application/json",
						"Authorization": getAuthToken()
					},	
				},
			).then(response => {
				this.CA_Actions = []
				this.removedIDs = []
				this.addedPoints = []
				response.data.history.forEach(action => {
					this.addCA_Action(action[0],action[1])
				})
				//console.log('call status: ', response.data)

			})
		},
		undoLastCa_Action(){
			var lastAction = this.CA_Actions.pop()
			//console.log(lastAction)
			if (!lastAction) {
				console.log('no action to undo')
			} else if (lastAction[0] == 'remove'){
				var layerClicked
				this.mapConfig.layers.criticalAssets.forEach(layer => {
					if (layer == lastAction[1][2]) {
						layerClicked = layer
					}
				})
				this.mapConfig.map.setFeatureState({
					source: layerClicked,
					sourceLayer: layerClicked,
					id: lastAction[1][0],
					}, {qced: false
				});
				this.removedIDs.pop(lastAction[1][0])
			} else if (lastAction[0] == 'includeAsset'){
				var layerClicked
				this.mapConfig.layers.criticalAssets.forEach(layer => {
					if (layer == lastAction[1][2]) {
						layerClicked = layer
					}
				})
				this.mapConfig.map.setFeatureState({
					source: layerClicked,
					sourceLayer: layerClicked,
					id: lastAction[1][0],
					}, {included: false
				});
				this.removedIDs.pop(lastAction[1][0])
			} else if (lastAction[0] == 'joinToBuilding'){
				if (lastAction[1][9].includes('-local-')) {
					var sourceNameToUse = this.mapConfig.layers.criticalAssets.slice(-1)[0]
				} else {
					var sourceNameToUse = this.mapConfig.layers.criticalAssets.slice(-2)[0]
				}
				this.mapConfig.map.setFeatureState({
					source: sourceNameToUse,
					sourceLayer: sourceNameToUse,
					id: lastAction[1][0],
					}, {qced: false
					});
				this.removedIDs.pop(lastAction[1][0])
				this.addedPoints.pop()
				this.updateAddedPoints()
			} else if (lastAction[0] == 'removeJoinedAsset'){
				this.updateAddedPoints()
			}

		},
		refreshMapPoints(){
			this.mapConfig.lastMapPoints = this.mapConfig.mapPoints
			this.mapConfig.mapPoints = new Array()
		},
		addToMapPoints(pointToAdd){
			this.mapConfig.mapPoints.push(pointToAdd)
		},
		makeCriticalAssetFilter(withBase){
			var countyAccess = this.user.access.counties 
			var cityAccess = this.user.access.cities 
			if (withBase) {
				var baseFilter = ['all', ['in', this.mapConfig.critAssetType, ['string', ['get', 'CA_class']]]]
			} else {
				var baseFilter = ['all']
			}
			if (countyAccess.length>0){
				baseFilter.push(['in', ['get','full_fips']].concat(countyAccess))
			}
			if (cityAccess.length>0){
				baseFilter.push(['in', ['get','city_name']].concat(cityAccess))
			}
			return baseFilter
		},
		setCritAssType(newType){
			this.mapConfig.critAssetType = newType
			if (newType == 'all') {
				var assetFilter = this.makeCriticalAssetFilter(false)
				this.mapConfig.map.setFilter('point', assetFilter)
			} else {
				var assetFilter = this.makeCriticalAssetFilter(true)
				this.mapConfig.map.setFilter('point', assetFilter)
			}
			this.mapConfig.layers['criticalAssets'].forEach(layer => {
				if (newType == 'all') {
					this.mapConfig.map.setFilter(layer, assetFilter)
				} else {
					this.mapConfig.map.setFilter(layer, assetFilter)
					// this is how to set opacity based on a substring
					//this.mapConfig.map.setPaintProperty(layer,'fill-opacity',["case",["boolean", ["in", this.mapConfig.critAssetType, ["string", ["get", "CA_class"]]]],0,1])
				}
			})
		},
		showLocalAssetsOnly(value){
			if (value == 'Yes') {
				this.mapConfig.map.setFilter('point', ['all', ['in', 'NoType', ['string', ['get', 'CA_class']]]])
				this.mapConfig.layers['criticalAssets'].forEach(layer => {
					if (!layer.includes('CAP_LOC')) {
						this.mapConfig.map.setFilter(layer, ['all', ['in', 'NoType', ['string', ['get', 'CA_class']]]])
					}
				})
			} else {
				this.setCritAssType(this.mapConfig.critAssetType)
			}
		},
		showOrHideAssetType(type,state){
			this.mapConfig.layers['criticalAssets'].forEach(layer => {
				if (layer.includes('CAPoly_') && layer.includes(type)) {
					if (state == 'hide') {
						this.polyInteraction = false
						this.mapConfig.map.setPaintProperty(layer, 'fill-opacity', 0)
					} else {
						this.polyInteraction = true
						this.mapConfig.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings['criticalAssets'].opacityPoly)
					}
				} else if (layer.includes('CAL_') && layer.includes(type)) {
					if (state == 'hide') {
						this.lineInteraction = false
						this.mapConfig.map.setPaintProperty(layer, 'line-opacity', 0)
					} else {
						this.lineInteraction = true
						this.mapConfig.map.setPaintProperty(layer, 'line-opacity', this.mapConfig.layerSettings['criticalAssets'].opacity)
					}
				}
			})
		},
		/////////////////////////////////////////////////////////////////
		// General Use Functions
		/////////////////////////////////////////////////////////////////
		addComponentRef(name, componentRef) {
			if ( componentRef && componentRef.$parent ) {
				this.componentRefs[name] = componentRef.$el
			} else {
				this.componentRefs[name] = componentRef
			}
		},
		getComponentRef(name) {
			return this.componentRefs[name]
		},
		setSidePanelView(newView) {
			let view = newView
			if (!newView) {
				view = this.$route.name + '-home'
			}
			event(view + '_subpage', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent(view + '_subpage','location ID: ' + window.location.href.split('/').slice(-1))
			this.sidePanel.currentView = view
		},
		startTour(location) {
			this.setTutorialVisibility(true)
			let id = '28-Mango-St_Clearwater_FL'
			let view = 'arkly'
			if (location) {
				id = location.id
				view = location.view
			}
			this.$router.push({name: view, params: { 'id': id, 'type': 'property'
			}})			
		},
		scheduleExpertMeeting: async function (price) {
			let source = this.$route.name
			if (source == 'arkly') {
				source = 'side-panel'
			}

			return this.checkout(price, source)
		},
		closeGoToPRModal(){
			this.goToPRModal = false
		},
		getUserIP: async function() {
			const instance = axios.create()

			return instance.get('https://api.ipify.org/?format=json') 
					.then(response => {
						this.userIP = response.data.ip

						// Check if IP is in Puerto Rico Area
						if (this.$route.meta.platform == 'arkly') {
							const ipLocation = this.userIP.split('.').slice(0,-1).reduce((acc, num) => acc + num)
							if (listOfIPs['puerto-rico'].includes(ipLocation)) {
								if (this.$route.name == "home"){
									this.goToPRModal = true
								}
							}
						}
					})
		},
		watchWindowSize() {
			window.addEventListener('resize', () => {
			this.windowDimensions = {
				width: window.innerWidth,
				height: window.innerHeight,
			}
			})
		},
		checkout: async function(price,source) {
			const instance = axios.create()
			var data = new FormData()
			data.append('domain_url', window.location.origin)	
			data.append('price', this.paymentConfig.prices[price])
			event('stripe_click', {'event_category': 'expert_consult', 'price' : price, 'source' : source, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('stripe_click','location ID: ' + window.location.href.split('/').slice(-1) + ', price: ' + price + ', click source: ' + source)

			//console.log(process.env)

			return instance.post(
				this.url + '/payment/create-checkout-session', 
				data, {
				headers: { "Content-Type": "multipart/form-data" }
			})
			.then(response => {	
				location.assign(response.data.stripe_checkout_url)
				event('stripe_click_response', {'event_category': 'expert_consult', 'price' : price, 'source' : source, 'response_code': response.data.status_code, 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('stripe_click','location ID: ' + window.location.href.split('/').slice(-1) + ', price: ' + price + ', click source: ' + source + ', response code: ' + response.data.status_code)
				return 
			}, this)
			.catch(error => {
				event('stripe_click_response', {'event_category': 'expert_consult', 'price' : price, 'source' : source, 'response_error': error, 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('stripe_click','location ID: ' + window.location.href.split('/').slice(-1) + ', price: ' + price + ', click source: ' + source + ', error: ' + error)
				//console.log(error)
				return
			})
		},
		abbreviateNumbers ( number, precision = 4 ) {

			const abbrev = {'':1, 'K':1000, 'M':1000000, 'B':1000000000}
			const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
			const order = Math.max(0, Math.min(unrangifiedOrder, Object.keys(abbrev).length -1 ))
			const suffix = Object.keys(abbrev)[order]

			if ((number / Math.pow(10, order * 3)) == 0) {
				return 0
			} else {
				return Number((number / Math.pow(10, order * 3)).toPrecision(precision)) + suffix
			}	
		},
		moveElementCSS(from,to,selector,positionName){
				setTimeout(() => {
					for (var i in document.styleSheets) {
						try {
							var rules = document.styleSheets[i].rules || document.styleSheets[i].cssRules;
							for (var r in rules) {
								if (rules[r].selectorText) {
									if (rules[r].selectorText.includes(selector)) {
										if (rules[r].cssText.includes(positionName + ': ' + from + 'px')) {
											var currString = rules[r].cssText.replace(positionName + ': ' + from + 'px', positionName + ': ' + to + 'px')
											document.styleSheets[i].deleteRule(r)
											document.styleSheets[i].insertRule(currString,r)
										}
									}
								}
							}
						} catch (e) {
							//do nothing with the error
						}
					}
				},100)
		},
		setTitle() {
			if (window.location.hostname.includes('arkly.com/pr')) {	
				document.title = 'Arkly Puerto Rico'
			} else if (window.location.hostname.includes('arkly.com')) {	
				document.title = 'Arkly'
			} else if (window.location.hostname.includes('hightide.ai')) {	
				document.title = 'Arkly Pro'
			} else {
				// Only used for Localhost
				if (this.$route.meta.platform == 'arkly') {
					document.title = 'Arkly'
				} else if (this.$route.meta.platform == 'govSub') {
					document.title = 'Arkly Pro'
				} else {
					document.title = 'Arkly'
				}
			}
		},
		setFavicon() {
			const arklyFavicon = require('@/assets/images/arkly-favicon.svg')
			const arklyProFavicon = require('@/assets/images/arkly-pro-favicon.svg')

			const browserColorScheme = this.getPreferredColorScheme()
			let favicon = arklyFavicon

			if (window.location.hostname.includes('hightide.ai')) {	
				favicon = arklyProFavicon 
			} else {
				// Only used for Localhost
				if (this.$route.meta.platform == 'govSub') {
					favicon = arklyProFavicon
				}
			}
			var linkElement = document.querySelector("link[rel~='icon']")
			if (!linkElement) {
				var linkElement = document.createElement('link')
				linkElement.rel = 'icon'
			} 
			linkElement.href = favicon
			document.head.appendChild(linkElement)
			
		},
		getPreferredColorScheme() {
			if (window.matchMedia) {
				if(window.matchMedia('(prefers-color-scheme: dark)').matches){
					return 'dark'
				} else {
					return 'light'
				}
			}
			return 'light'
		},
		escapeRegExp(str) {
        	return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    	},
		replaceAll(str, find, replace) {
       		return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    	},
		svgToDataUri(svg) {
			// these may not all be needed - used to be for uri-encoded svg in old browsers
			var encoded = svg.replace(/\s+/g, " ")
			encoded = this.replaceAll(encoded, "%", "%25");
			encoded = this.replaceAll(encoded, "> <", "><"); // normalise spaces elements
			encoded = this.replaceAll(encoded, "; }", ";}"); // normalise spaces css
			encoded = this.replaceAll(encoded, "<", "%3c");
			encoded = this.replaceAll(encoded, ">", "%3e");
			encoded = this.replaceAll(encoded, "\"", "'"); // normalise quotes ... possible issues with quotes in <text>
			encoded = this.replaceAll(encoded, "#", "%23"); // needed for ie and firefox
			encoded = this.replaceAll(encoded, "{", "%7b");
			encoded = this.replaceAll(encoded, "}", "%7d");
			encoded = this.replaceAll(encoded, "|", "%7c");
			encoded = this.replaceAll(encoded, "^", "%5e");
			encoded = this.replaceAll(encoded, "`", "%60");
			encoded = this.replaceAll(encoded, "@", "%40");
			var dataUri = 'data:image/svg+xml;charset=UTF-8,' + encoded.trim();
			return dataUri;
		},
		/////////////////////////////////////////////////////////////////
		// Directus
		/////////////////////////////////////////////////////////////////
		setUserLanguage(newLanguage) {
			event('language_change_' + newLanguage.replace('-','_'), {'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('language_change_' + newLanguage.replace('-','_'),'location ID: ' + window.location.href.split('/').slice(-1))
			this.userLanguage = newLanguage
			this.textContent = this.text[newLanguage]
		},
		selectTranslation(dataFromCMS, language) {
			const chooseTranslation = (obj) => {
				if ('translations' in obj) {
					const translated = obj['translations'].find(fields => {

						//console.log('fields: ', fields)
					// Select translated data to return based on language selected
						return fields['languages_code'] == language
					})

					// Combine translated data with data that doesn't need
					// to be translated
					return {...obj, ...translated}
				} else {
					return obj
				}
			}

			if (Array.isArray(dataFromCMS)) {
				dataFromCMS = dataFromCMS.map(section => {
					//console.log('is array', section)
					const translated = chooseTranslation(section)
					return { [section['id']]: _.omit(translated, 'id') }
				})
				
				// Combine each Obj in Array into One large Obj
				dataFromCMS = dataFromCMS.reduce((acc, section, inc) => {
					return {...acc, ...section}
				}, Object.create(null))

				return dataFromCMS
				
			} else {
				//console.log('is not array', dataFromCMS)
				return  chooseTranslation(dataFromCMS)				
			}
		},
		async loadCmsQuery(collection){

			try {
				var result = await directus.request(
					// Return all fields in the selected collection
					readItems(collection, {
						fields: ['*.*', {'translations': ['*']}],
					})
				)
			} catch (error) {
				console.log(error)
				var result = undefined
			}

			let backup = []
			if (collection == 'footer') {
				backup = BackupTranslationsFooter
			} else if (collection == 'brand') {
				backup = BackupTranslationsBrand
			} else if (collection == 'buttons') {
				backup = BackupTranslationsButtons
			} else if (collection == 'homepage') {
				backup = BackupTranslationsHomepage
			} else if (collection == 'insurance') {
				backup = BackupTranslationsInsurance
			} else if (collection == 'mitigation') {
				backup = BackupTranslationsMitigation
			} else if (collection == 'property_view') {
				backup = BackupTranslationsPropertyView
			} else if (collection == 'rocket_flood') {
				backup = BackupTranslationsRocketflood
			} else if (collection == 'tutorial') {
				backup = BackupTranslationsTutorial
			}
			if (result) {
				if (collection == 'brand') {
					if (result['date_updated'] != backup["date_updated"]) {
						console.log("brand backup collection needs updating")
					}
				} else if (result.length != backup.length) {
					console.log(collection + " backup translation needs updating")
					console.log(result)
				} else if (this.isDevOrLocal) {
					var matchFlag = true
					for (let i = 0; i < result.length; i++) {
						if (result[i]['date_updated']) {
							matchFlag = result[i]['date_updated'] == backup[i]['date_updated']
							if (!matchFlag) {
								console.log(collection + " backup translation needs updating")
								console.log(result)
								break
							}
						}
					}
				}
			} else {
				result = backup
			}

			if (collection == 'tutorial') {
				result.map(step => {
					let formatted = step
					formatted['regions'] = step.regions.map(region => region.regions_id)
					return formatted
				})
			}

			// Filter the results based on the language the user is using
			this.text['en-US'][collection] = this.selectTranslation(result, 'en-US')
			this.text['es-ES'][collection] = this.selectTranslation(result, 'es-ES')
		
			/*
			if (BackupTranslations && collection == this.cmsCollections[this.cmsCollections.length - 1] 
				&&
				this.text != BackupTranslations) 
			{
				//console.log('newest cms data: ', this.text)
			}
			*/
			
			if (this.userLanguage == 'es-ES') {
				this.textContent[collection] = this.text['es-ES'][collection]
			} else {
				this.textContent[collection] = this.text['en-US'][collection]
			}
			return this.textContent
		},
		setTextContent(section, value) {
			this.textContent[section] = value
		},
		/////////////////////////////////////////////////////////////////
		// Map Actions
		/////////////////////////////////////////////////////////////////
		addMapboxLayer(layer, map, layerToInsertUnder) {
			map.addSource(layer, {
				type: 'vector',
				url: 'mapbox://hightideintelligence.' + layer,
				});
			map.addLayer({'id': layer,'type': 'fill','source': layer, 'source-layer': layer,'layout': {},'paint': {'fill-color': '#ffffff'}}, layerToInsertUnder);
			if (layer.includes('bgs') || layer.includes('blockgroup') || layer.includes('counties') || layer.includes('equity')) {
				map.addSource(layer + '_outline', {
					type: 'vector',
					url: 'mapbox://hightideintelligence.' + layer,
					});
				map.addLayer({'id': layer + '_outline','type': 'line','source': layer, 'source-layer': layer,'layout': {},'paint': {'line-color': '#ffffff','line-width':0}}, 'house-numbers');
			}
		},
		setStartingZoom(zoom) {
			this.mapConfig.startingZoom = zoom
		},
		setPropertyLayer(newLayer) {
			this.mapConfig.layers.property = newLayer
		},
		setMapHandle(newHandle) {
			this.mapConfig.map = newHandle
		},	
		toggleEconomicLayers(newVisibility){
			Object.keys(this.mapConfig.layers).forEach(layerType => {
				if (layerType != 'equity' && layerType != 'floodZone'){
					var keys = Object.keys(this.mapConfig.layers[layerType]);
					keys.forEach(currLayer => {
						this.mapConfig.map.setLayoutProperty(this.mapConfig.layers[layerType][currLayer], 'visibility', newVisibility)
						if (layerType == 'county' || layerType == 'equity' || layerType == 'blockgroup') {
							this.mapConfig.map.setLayoutProperty(this.mapConfig.layers[layerType][currLayer] + '_outline', 'visibility', newVisibility)
						}
					})
					
				}
			})
		},
		setMapConfigVersion(version) {
			const platform = this.$route.meta.platform
			this.mapConfig['layers'] = this.mapConfig[ platform + 'Layers' ]
			this.mapConfig['style'] = this.mapConfig[ platform + 'Style' ]
		},
		addDraw(draw){
			this.draw = draw
		},
		updateToggleDrawMode(newState, name){
			this.controls.drawMode = newState
			this.controls.drawModeName = name
			if (newState) {
				this.draw.changeMode('draw_polygons')
			} else {
				this.draw.changeMode('simple_select')
			}
		},
		updateToggleSumStatsDrawMode(newState){
			this.controls.drawMode = newState
			if (newState) {
				this.draw.changeMode('draw_polygons')
			} else {
				this.draw.changeMode('simple_select')
			}
		},
		updateZoomState(newState){
			this.zoomedIn = newState
		},
		submitBuildingFootprints: async function(polys) {
			this.CA_SaveStatus = undefined
			const instance = axios.create()

			let url = `${this.url}/auth/submit-building-footprints`	

			var data = new FormData()
			data.append('footprints', JSON.stringify(polys))

			return	instance.post(
				url, data, {
					signal: controller.signal,
					headers: {
						"Content-Type": "multipart/form-data",
						"Authorization": getAuthToken()
					},	
				},
			).then(response => {
				if (response.data.status) {
					this.CA_SaveStatus = true
				} else {
					this.CA_SaveStatus = false
				}
			})
			
		},
		deleteLastDrawn(){
			var currPolys = this.draw.getAll()
			this.draw.delete(currPolys.features[currPolys.features.length-2].id)
		},
		deleteSelected(){
			const selectedIds = this.draw.getSelectedIds()
			console.log(selectedIds)
			for(var i=0; i<selectedIds.length; i++){
				this.draw.delete(selectedIds[i])
			}

		},
		deletePolys(){
			const initialMode = this.draw.getMode()
			this.draw.deleteAll()
			if (initialMode == 'draw_polygons') {
				this.draw.changeMode('draw_polygons')
			}
		},
		updateMapboxLayer() {
			const key = `AAL_${this.controls.dataSource}_${this.controls.year}_mean`
			const newKey = this.mapboxLookupDict[key]
			//console.log("Old key is: " + key + ", new key is: " + newKey)
			/*
			if (this.$route.meta.platform == 'arkly') {
				if (this.$route.name == 'puertoRico') {
					this.mapConfig.currentLayer = newKey
				} else {
					this.mapConfig.currentLayer = `AAL_${this.controls.dataSource}_${this.controls.year}_mean`
				}
			} else {
				*/

			if (this.$route.name == 'puertoRico') {
				this.mapConfig.currentLayer = newKey
			} else {
				if (this.controls.year) {
					this.mapConfig.currentLayer = `expectedLoss_${this.controls.dataSource}_${this.controls.storm.replace(/\D/g, '') + 'year'}_${this.controls.year}_mean`
					//console.log(this.mapConfig.currentLayer)
				}
			}

		},
		/////////////////////////////////////////////////////////////////
		// GoTo Functions
		/////////////////////////////////////////////////////////////////
		goToRocketFlood: async function(source) {
			window.gtag('event', 'conversion', {'send_to': this.AdsConversionGtagID})
			//window.location.replace('https://arkly.rocketflood.com/')
			event('rocketflood_homepage_click', {'event_category': 'rocketflood', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('rocketflood_click','location ID: ' + window.location.href.split('/').slice(-1) + ', click source: ' + source)
			window.open('https://arkly.rocketflood.com/', '_blank')
		},
		goToFacebook: async function() {
			event('terms_click', {'event_category': 'footer_click', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('facebook_click','location ID: ' + window.location.href.split('/').slice(-1))
			window.open('https://www.facebook.com/arkly.insurance', '_blank')
		},
		goToTerms() {
			event('terms_click', {'event_category': 'footer_click', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('terms_click','location ID: ' + window.location.href.split('/').slice(-1))
			this.$router.push({ name: 'terms' })
		},
		goToPrivacy() {
			event('privacy_click', {'event_category': 'footer_click', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('privacy_click','location ID: ' + window.location.href.split('/').slice(-1))
			window.open('https://hightide.ai/privacy-policy/', '_blank')
		},
		goToHighTide() {
			event('hightide_click', {'event_category': 'footer_click', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('hightide_click','location ID: ' + window.location.href.split('/').slice(-1))
			window.open('https://hightide.ai/', '_blank')
		},
		goToBCorp() {
			event('bcorp_click', {'event_category': 'footer_click', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('bcorp_click','location ID: ' + window.location.href.split('/').slice(-1))
			window.open('https://www.bcorporation.net/', '_blank')
		},
		goToEchelon() {
			event('hightide_click', {'event_category': 'footer_click', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('hightide_click','location ID: ' + window.location.href.split('/').slice(-1))
			window.open('https://echelon.xyz/', '_blank')
		},
		goToCCRS() {
			event('ccrs_click', {'event_category': 'footer_click', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('ccrs_click','location ID: ' + window.location.href.split('/').slice(-1))
			window.open('https://prsciencetrust.org/ccrs/', '_blank')
		},
		goToContact() {
			event('hightide_contact_click', {'event_category': 'footer_click', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('hightide_contact_click','location ID: ' + window.location.href.split('/').slice(-1))
			window.open('https://hightide.ai/contact-us/', '_blank')
		},
		meetTheTeam() {
			event('hightide_team_click', {'event_category': 'footer_click', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('hightide_team_click','location ID: ' + window.location.href.split('/').slice(-1))
			window.open('https://hightide.ai/about-us/', '_blank')
		},
		goHome(source) {
			if (this.mapConfig.arklyRoutes.includes(this.$route.name)){
				if (this.isMobile) {
					this.moveElementCSS('30','-5','.mapboxgl-ctrl-logo','bottom')
					this.moveElementCSS('15','20','.mapboxgl-ctrl-bottom-right','bottom')
					this.moveElementCSS('-35','0','.mapboxgl-ctrl-top-right','top')
					this.moveElementCSS('35','0','.mapboxgl-ctrl-top-right','top')
				} else {
					this.moveElementCSS('95','-5','.mapboxgl-ctrl-logo','bottom')
					this.moveElementCSS('0','20','.mapboxgl-ctrl-bottom-right','bottom')
					this.moveElementCSS('-10','0','.mapboxgl-ctrl-top-right','top')
				}
			}
			event('logo_click_' + source, {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('logo_click_' + source,'location ID: ' + window.location.href.split('/').slice(-1))
			if (this.$route.name == this.mapConfig.arklyRoutes[1]){
				this.$router.push({name: 'puertoRicoHome'})	
			} else {
				this.$router.push({name: 'home'})
			}
		},
		/////////////////////////////////////////////////////////////////
		// Set Functions
		/////////////////////////////////////////////////////////////////
		setSidePanelVisibility(newVisibility) {
			//console.log(this.isSidePanelVisible,newVisibility)
			this.isSidePanelVisible = newVisibility
		},
		toggleEquityVisibility(newVisibility){
			if (newVisibility) {
				this.mapConfig.map.setLayoutProperty(this.mapConfig.layers.equity, 'visibility', 'visible')
				this.mapConfig.map.setLayoutProperty(this.mapConfig.layers.equity + '_outline', 'visibility', 'visible')
				this.toggleEconomicLayers('none')
				
			} else {
				this.toggleEconomicLayers('visible')
				this.mapConfig.map.setLayoutProperty(this.mapConfig.layers.equity, 'visibility', 'none')
				this.mapConfig.map.setLayoutProperty(this.mapConfig.layers.equity + '_outline', 'visibility', 'none')
			}
		},
		setFloodZoneVisibility(newVisibility) {
			this.controls.isFloodZoneVisible = newVisibility
		},
		setTutorialVisibility(newVisibility) {
			if (newVisibility) {
				event('tutorial_start', {'event_category': 'tutorial', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('tutorial_opened','location ID: ' + window.location.href.split('/').slice(-1))
			}

			this.tutorial.isVisible = newVisibility
		},
		setNavMenuVisibility(newVisibility) {
			this.isNavMenuVisible = newVisibility
		},
		setRouteIsLocation(value){
			this.isRouteLocation = value
			if (value && !this.isMobile) {
				this.setSidePanelVisibility(false)
			}
			if (!value && !this.isMobile) {
				this.setSidePanelVisibility(true)
			}
		},
		setZoom(newZoom) {
			this.mapConfig.zoom = newZoom
		},
		setLocation(newLocation) {
			this.location = newLocation	
		},
		setControls(newControls) {
			this.controls = newControls
			this.updateMapboxLayer()
		},
		setBaselayer(newLayer) {
			this.controls.baselayer = newLayer
		},
		changeTimeLine(newUnit) {
			this.controls.timeLineUnit = newUnit
		},
		setUnits(newUnits) {
			this.controls.units = newUnits
			this.updateMapboxLayer()
		},
		setDataSource(newDataSource) {
			this.controls.dataSource = newDataSource
			this.updateMapboxLayer()
		},
		setYear(newYear) {
			this.controls.year = newYear
			this.updateMapboxLayer()
		},
		setStorm(newStorm) {
			this.controls.storm = newStorm
			this.updateMapboxLayer()
		},
		setElevation(newElevation) {
			this.controls.elevation = newElevation
		},
		setNavbarHeight(newHeight) {
			this.navbarConfig.height = newHeight
		},
		setTopbarHeight(newHeight) {
			this.topbarConfig.height = newHeight
		},
		setDevOrLocal(newState) {
			this.isDevOrLocal = newState
		},
		setMapSearchOptions(options) {
			if (options == false) {
				this.mapConfig.searchOptions = []
			} else {
				console.log(options)
				this.setSidePanelVisibility(false)
				this.mapConfig.searchOptions = options
			}
		},
		setMapDimensions(newHeight, newWidth) {	
			//this.mapConfig.dimensions.height = newHeight
			this.mapConfig.dimensions.width = newWidth
		},
		setYearOptions(newListOfYears) {
			this.settings['years'] = newListOfYears
		},
	}
})
