<template>
<!-- Sidebar Menu -->
<div ref='topbar' class='topbar'>
		<modal v-model="saveModal">
			<div class='criticalAssetPopup'>
				<div v-if="CA_SaveStatus">Saved.</div>
				<div v-else-if="CA_SaveStatus == false">Save Failed... Please Try Again.</div>
				<div v-else>Saving...</div>
				<div class='criticalAssetButtons'>
					<btn variant="arkly" label="saved" outlined size='medium' v-on:click="saveModal = !saveModal">Great!</btn>
				</div>
			</div>
		</modal>
	<span class='control-container'>
		<control-panel
			v-if="$route.name != 'result' || user.access.role.layers"
			label='Layers'
		>
			<control	
				label='Flood Impacts'
				:selected="localEquityVisible ? 'Socioeconomic' : 'Economic'"
				icon='layer-group'
				:options="['Economic', 'Socioeconomic']"
				@selection='handleControl'
			/>
			<hr class="control-divider">
			<control	
				label='Basemap'
				selected='Mapbox'
				icon='layer-group'
				:options="['Mapbox', 'Satellite']"
				@selection='handleControl'
			/>
			<hr class="control-divider">
			<control
				label='Flood Zones'
				type='switch'
				:selected='floodZoneVisibility'
				@selection='changeInFloodZone'
			/>
			

		</control-panel>
		<control-panel
			label='Scenarios'
		>
			<control
				label='Storm Surge'
				:selected="controls.storm"
				icon='hurricane'
				:options="currentSettings.storm"
				@selection='handleControl'
				type="dropdown"
				zIndex=5
				style="z-index: 5;"
			/>
			<hr class="control-divider">
			<control
				label='Projection'
				:selected='controls.dataSource'
				icon='earth'
				:options="currentSettings.dataSource"
				@selection='handleControl'
				type="dropdown"
				zIndex=4
				style="z-index: 4;"
			/>
			<hr class="control-divider" v-if="($route.name != 'result' || user.access.role.units ) && currentSettings.units">
			<control
				v-if="($route.name != 'result' || user.access.role.units ) && currentSettings.units"
				label='Units'
				:selected='controls.units'
				icon='sack-dollar'
				:options="settings.units"
				@selection='handleControl'
				type="dropdown"
				zIndex=3
				style="z-index: 3;"
			/>
			<hr class="control-divider">
			<control
				label='Decade'
				:selected='controls.year'
				:options="getYears"
				type='dropdown'
				@selection='handleControl'
				zIndex=2
				style="z-index: 2;"
			/>
			<!--<control
				label='Decade'
				:selected='controls.year'
				icon='calendar'
				type='slider'
				:range='getYearRange'
				@selection='handleControl'
			/>-->

		</control-panel>
		<control-panel
			v-if="($route.name != 'result' || user.access.role.mitigation) && $route.params.view == 'economic' && $route.params.type == 'property'"
			label='Adaptation'
		>
			<elevation-calculator 
				:isVisible='true'
				@changeInElevation='handleElevationCalculator'
			/>
		</control-panel>
		<control-panel
			label='Critical Assets'
			v-if="!isMobile"
		>
		<control
			label='Critical Asset Types'
			:selected='critAssSelected'
			:options='critAssOptions'
			type='dropdown'
			@selection='handleControl'
			zIndex=4
			style="z-index: 4;"
			/>
		<hr v-if="critAssSelected != 'None'" class="control-divider">
		<control
			v-if="critAssSelected != 'None'"
			label='Polygon Assets'
			:selected='polyAssetsSelected'
			:options='polyAndLineAssetsOptions'
			type='dropdown'
			@selection='handleControl'
			zIndex=3
			style="z-index: 3;"
		/>
		<hr v-if="critAssSelected != 'None'" class="control-divider">
		<control
			v-if="critAssSelected != 'None'"
			label='Line Assets'
			:selected='lineAssetsSelected'
			:options='polyAndLineAssetsOptions'
			type='dropdown'
			@selection='handleControl'
			zIndex=2
			style="z-index: 2;"
		/>
		<hr v-if="critAssSelected != 'None'" class="control-divider">
		<control
			v-if="critAssSelected != 'None'"
			label='Local Assets Only'
			:selected='showOnlyLocal'
			:options='showOnlyLocalOptions'
			@selection='handleControl'
			type='dropdown'
		/>
		<hr v-if="critAssSelected != 'None'" class="control-divider">
		<control
			v-if="critAssSelected != 'None'"
			label='Actions'
			:options="CA_Actions"
			@selection='handleControl'
			:selected='Ca_Action_Selected'
			noSelected=true
		/>
		<hr v-if="critAssSelected != 'None' && isDevOrLocal" class="control-divider">
		<control
			v-if="critAssSelected != 'None' && isDevOrLocal"
			label='Vulnerability Report'
			:options='["Generate"]'
			@selection='handleControl'
			:selected='Ca_Action_Selected'
			noSelected=true
		/>

			

		</control-panel>
		<control-panel
			label='Data Tools'
			v-if="!isMobile && $route.params.view == 'economic'"
		>
		<h3 v-if="(zoomedIn==false)" style='color: #4E84C4; margin: 5px 2.5px; padding: 0 10px 0 10px;'>Zoom in to use the data tools</h3>
		<control
				v-if="zoomedIn"
				label='Draw Mode'
				:selected='drawModeSelected'
				:options='drawOptions'
				type='dropdown'
				@selection='handleControl'
				zIndex=2
				style="z-index: 2;"
			/>
			<!--
			<control
				v-if="zoomedIn"
				label='Draw Building Footprints'
				type='switch'
				:selected='controls.drawMode'
				@selection='toggleDrawMode'
			/>
			<hr v-if="zoomedIn" class="control-divider">
			<control
				v-if="zoomedIn"
				label='Actions'
				icon='hurricane'
				:options="currentSettings.footprintActions"
				@selection='handleControl'
			/>
			
			<hr class="control-divider">
			<control
				label='Draw Summary Statistics Boundary'
				type='switch'
				:selected='controls.sumStatsDrawMode'
				@selection='toggleSumStatsDrawMode'
			/>
			-->
			<hr v-if="zoomedIn && drawModeSelected != 'Off'" class="control-divider">
			<control
				v-if="zoomedIn && drawModeSelected != 'Off'"
				label='Actions'
				icon='hurricane'
				:options="currentActions"
				@selection='handleControl'
				noSelected=true
			/>
			<hr v-if="zoomedIn && drawModeSelected == 'Summary Statistics' && queryResults" class="control-divider">
			<control
				v-if="zoomedIn && queryResults && drawModeSelected == 'Summary Statistics'"
				label='Results'
				:options="result_actions"
				@selection='handleControl'
				:selected='result_Action_Selected'
				noSelected=true
			/>
		</control-panel>
	</span>
    <modal v-model="resultsModalActive">
		<dataTable @closeModal="resultsModalActive = !resultsModalActive"></dataTable>
	</modal>
	<VulnerabilityReport v-model='isReportVisible'/>
</div>
</template>
<script>
import InlineSvg from 'vue-inline-svg'
import Search from '@/components/Search.vue'
import ControlPanel from '@/components/govSub/ControlPanel'
import Control from '@/components/govSub/Control'
import ElevationCalculator from '@/components/govSub/ElevationCalculator'
import { isLoggedIn, logoutUser, getUserInfo } from '@/helpers/auth.js'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import Modal from '@/components/Modal'
import dataTable from '@/components/DataTable'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import Button from '@/components/Button'
import VulnerabilityReport from '@/components/govSub/VulnerabilityReport'

export default {
	name: 'Sidebar',
	components: {
		InlineSvg,	
		Search,
		ControlPanel,
		Control,
		ElevationCalculator,
		dataTable,
		'modal': Modal,
		'btn': Button,
		VulnerabilityReport,
	},
	props: {
		collapsedWidth: {
			type: Number,
			default: 68
		},
		expandedWidth: {
			type: Number,
			default: 220
		}
	},
	data() {
		return {
			saveModal: false,
			currentWidth: this.collapsedWidth,
			windowWidth: null,
			isCollapsed: true,
			drawMode: undefined,
			floodZoneVisibility: undefined,
			localEquityVisible: undefined,
			resultsModalActive: false,
			drawOptions: ["Off","Summary Statistics","Building Footprints"],
			drawModeSelected: "Off",
			critAssSelected: "None",
			critAssOptions: ["None","Utility","Transport","NCH","CCF","Emergency","Government","All"],
			currentActions: [],
			CA_Actions: ["Undo","Save","Load"],
			Ca_Action_Selected: undefined,
			result_actions: ["Show Results"],
			result_Action_Selected: undefined,
			polyAndLineAssetsOptions: ["Hide","Show"],
			polyAssetsSelected: "Hide",
			lineAssetsSelected: "Hide",
			showOnlyLocal: 'No',
			showOnlyLocalOptions: ["Yes","No"],
			isReportVisible: false,
		}
	},
	computed: {
		getYears(){
			const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
			if (!this.localEquityVisible){
				return range(2020, Math.max(...this.settings.years), 10)//{'min': 2020, 'max': Math.max(...this.settings.years), 'step': 10}
			} else {
				return range(2020, Math.max(...this.equitySettings.years), 10)// {'min': 2020, 'max': Math.max(...this.equitySettings.years), 'step': 10}

			}
		},
		getYearRange(){
			if (!this.localEquityVisible){
				return {'min': 2020, 'max': Math.max(...this.settings.years), 'step': 10}
			} else {
				return {'min': 2020, 'max': Math.max(...this.equitySettings.years), 'step': 10}

			}
		},
		...mapState(useSurfStore, [
			'location',
			'queryResults',
			'fetching',
		]),
		...mapState(useConfigStore, [
			'CA_SaveStatus',
			'isEquityVisble',
			'mapConfig',
			'isMobile',
			'isDevOrLocal',
			'zoomedIn', 
			'settings', 
			'equitySettings',
			'controls', 
			'user', 
			'currentSettings',
			'draw'
		]),
	},
	created() {
		if (this.$route.params.view == 'economic') {
			this.localEquityVisible = false
		} else {
			this.localEquityVisible = true
		}
	},
	mounted() {
		window.addEventListener('resize', this.windowResized )
		this.floodZoneVisibility = this.controls['isFloodZoneVisible']
		this.drawMode = this.controls['drawMode']
		this.setTopbarHeight(this.$refs.topbar.getBoundingClientRect().height)
		this.setNavbarHeight(this.$refs.topbar.getBoundingClientRect().top)
		this.$emit('controls', {'units': this.controls.damageUnits, 'dataSource': this.controls.dataSource, 'year': this.controls.year, 'stormCategory': this.controls.storm	})
	},
	methods: {
		changeInFloodZone(newOption) {
			this.setFloodZoneVisibility(newOption.value)
		},
		toggleDrawMode(newOption) {
			this.updateToggleSumStatsDrawMode(false)
			this.updateToggleDrawMode(newOption.value)
		},
		toggleSumStatsDrawMode(newOption) {
			this.updateToggleDrawMode(false)
			this.updateToggleSumStatsDrawMode(newOption.value)
		},
		handleElevationCalculator(elevation) {
			this.$emit('elevationCalculator', elevation)
		},
		handleControl(selection) {
			if (selection.label == 'flood impacts'){
				//console.log(this.controls.year)
				// TODO: put this max year of socioeconomic into the config store
				if (selection.value == 'Socioeconomic') {
					this.moveElementCSS('85','115','.mapboxgl-ctrl-logo','bottom')
					if (this.controls.year > 2050) {
						this.setYear( 2050 )
						console.log(this.controls.year)
					}
					this.localEquityVisible = true
					this.toggleEquityVisibility(true)
					this.mapConfig.isEquityVisible = true
				} else {
					this.moveElementCSS('115','85','.mapboxgl-ctrl-logo','bottom')
					this.localEquityVisible = false
					this.toggleEquityVisibility(false)
					this.mapConfig.isEquityVisible = false
				}
				
				
				//this.map.setLayoutProperty(this.mapLayers.equity + '-outline', 'visibility', 'visible')
				//this.$router.push({name: 'economic', params: { view: selection.value, type: this.$route.params.type, id: this.$route.params.id}})
			}
			//////////////////////////////////
			// Storm Category Work Around
			//////////////////////////////////
			const stormCategoryLookup = {
				'1 Year': 'category1',
				'5 Year': 'category2',
				'10 Year': 'category3',
				'50 Year': 'category4',
				'100 Year': 'category5'
			}
			/////////////////////////////////
			
			const units = {
				'$': 'total',
				'$/sqft': 'average'
			}

			switch (selection.label) {
				case 'vulnerability report':
					this.isReportVisible = true
					break
				case 'local assets only':
					this.showLocalAssetsOnly(selection.value)
					this.showOnlyLocal = selection.value
					break
				case 'basemap':
					this.$gtag.event( 'basemap-changed', { 
						control: 'basemap',
						newValue: selection.value 
					})
					this.setBaselayer( selection.value )
					break
				case 'units':
					this.$gtag.event( 'control-changed', { 
						control: 'units',
						newValue: selection.value 
					})
					this.setUnits( selection.value )
					break
				case 'projection':
					this.$gtag.event( 'control-changed', { 
						control: 'projection',
						newValue: selection.value 
					})
					this.setDataSource( selection.value )
					break
				case 'decade':
					this.$gtag.event( 'control-changed', { 
						control: 'decade', 
						newValue: selection.value 
					})
					this.setYear( selection.value )
					break
				case 'storm surge':
					this.$gtag.event( 'control-changed', { 
						control: 'storm surge',
						newValue: selection.value 
					})
					this.setStorm( selection.value )
					break
				case 'draw mode':
					switch (selection.value){
						case 'Summary Statistics':
							this.updateToggleDrawMode(true,'Summary Statistics')
							this.currentActions = this.currentSettings.sumStatsActions
							this.drawModeSelected = 'Summary Statistics'
							console.log(selection.value)
							break
						case 'Building Footprints':
							this.updateToggleDrawMode(true,'Building Footprints')
							this.currentActions = this.currentSettings.footprintActions
							this.drawModeSelected = 'Building Footprints'
							console.log(selection.value)
							break
						case 'Off':
							this.updateToggleDrawMode(false,'None')
							this.currentActions = []
							this.drawModeSelected = 'Off'
							console.log(selection.value)
							break
					}
				case 'results':
					switch (selection.value) {
						case 'Show Results':
							this.resultsModalActive = !this.resultsModalActive
							break
					}
					break
				case 'actions':
					switch (selection.value) {
						case 'Undo':
							this.undoLastCa_Action()
							break
						case 'Save':
							this.saveModal = true
							this.saveCA_Action()
							break
						case 'Load':
							this.loadCA_Action()
							break
						case 'Submit':
							this.saveModal = true
							const polys = this.draw.getAll().features
							var polysToSubmit = []
							polys.forEach(poly => {
								if (poly.geometry.coordinates[0].length > 2) {
									polysToSubmit.push(poly.geometry.coordinates[0])
								}
							})
							this.submitBuildingFootprints(polysToSubmit)
							break
						case 'Delete Selected':
							this.deleteSelected()
							console.log(selection.value)
							break
						case 'Delete Last Drawn':
							this.deleteLastDrawn()
							console.log(selection.value)
							break
						case 'Delete All':
							this.deletePolys()
							console.log(selection.value)
							break
						case 'Calculate':
							console.log(selection.value)
							if (this.mapConfig.mapPoints[0]) {
								var maxX = this.mapConfig.mapPoints[0]['x']
								var minX = this.mapConfig.mapPoints[0]['x']
								var maxY = this.mapConfig.mapPoints[0]['y']
								var minY = this.mapConfig.mapPoints[0]['y']
							}
							this.mapConfig.mapPoints.forEach(item => {
								if (item['x']> maxX) {
									maxX = item['x']
								} else if (item['x']< minX) {
									minX = item['x']
								}
								if (item['y']> maxY) {
									maxY = item['y']
								} else if (item['y']< minY) {
									minY = item['y']
								}

							})
							this.resultsModalActive = true
							const features = map.queryRenderedFeatures([[maxX, maxY], [minX, minY]], {
								layers: ['properties-florida-govSub-v3-3']
							});
							var featuresToSend = new Array()
							features.forEach(item => {
								featuresToSend.push(item['properties']['structureId'])
							})
							//console.log(this.draw.getAll().features[0].geometry.coordinates[0])
							//console.log(featuresToSend)
							this.getPropertiesInPoly(this.draw.getAll().features[0].geometry.coordinates[0])
							//this.getProperties(featuresToSend)
							this.refreshMapPoints()
							break
					}
					break
				case 'critical asset types':
					var selectedType = selection.value
					this.critAssSelected = selectedType
					if (!(["NCH","CCEF","CCF"].includes(selectedType))) {
						selectedType = selectedType.toLowerCase()
					}
					if (selectedType == "CCF") {
						this.setCritAssType("CCEF")
					} else {
						this.setCritAssType(selectedType)
					}
					break
					//switch (selection.value){
					//	console.log(selection.value)
					//	break
					//}
				case 'polygon assets':
					this.polyAssetsSelected = selection.value
					this.showOrHideAssetType('CAPoly',this.polyAssetsSelected.toLowerCase())
					break
				case 'line assets':
					this.lineAssetsSelected = selection.value
					this.showOrHideAssetType('CAL_',this.lineAssetsSelected.toLowerCase())
					break
				default: 
					break
			}

			//const stormCategory = (this.$route.params.type == 'property') ? this.stormCategory.toLowerCase().replace(' ', '') : stormCategoryLookup[this.stormCategory]

			// Mapbox does not allow nested values, so we need to convert to convert
			// each value into a seperate variable
			this.$emit('controls', {'units': this.controls.damageUnits, 'dataSource': this.controls.dataSource, 'year': this.controls.year, 'stormCategory': this.controls.storm	})
		},
		windowResized() {

		},
		redirect(path) {
			this.$router.push({name: path, params: { type: this.$route.params.type, id: this.$route.params.id}})
		},
		onExpand() {
			console.log('on click')
			if (this.currentWidth == this.collapsedWidth) {
				this.currentWidth = this.expandedWidth
				this.isCollapsed = false
			} else {
				this.currentWidth = this.collapsedWidth
				this.isCollapsed = true
			}
		},
		getLogoPath(name) {
			var logo = require.context('../../assets/icons', false, /\.svg$/)
			return logo('./' + name + ".svg")
		},
		...mapActions(useSurfStore, ['getProperties','getPropertiesInPoly','downloadObjectAsJson']),
		...mapActions(useConfigStore, [
			'showLocalAssetsOnly',
			'submitBuildingFootprints',
			'loadCA_Action',
			'saveCA_Action',
			'undoLastCa_Action',
			'showOrHideAssetType',
			'setCritAssType',
			'moveElementCSS',
			'refreshMapPoints',
			'abbreviateNumbers',
			'toggleEquityVisibility',
			'updateToggleDrawMode',
			'updateToggleSumStatsDrawMode',
			'deleteLastDrawn',
			'deletePolys',
			'deleteSelected',
			'setBaselayer',
			'setControls', 
			'setUnits', 
			'setDataSource', 
			'setYear',
			'setStorm',
			'setTopbarHeight', 
			'setNavbarHeight',
			'setFloodZoneVisibility',
		]),
	},
	watch: {
		windowWidth: function(newValue) {
			console.log(newValue)	
		},
		windowSize: function(newValue) {
			
		},
		floodZoneVisibility(newState) {
				
		},
		'$route': {
			handler: function(result) {
				this.isLoggedIn = isLoggedIn()
			},
			deep: true,
		},
		currentSettings: {
			handler: function(result) {
				//console.log(result.units)

			},
			deep: true,
		},
		style(newStyle) {
			//console.log(newStyle)
		}
	},
}

</script>

<style lang="scss" scoped>
@import "../../style/style.scss"; 

.topbar {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: auto 1fr;
	width: 100%;
	max-height: 33px;
	background-color: $blue;	
	overflow: visible;
	padding-right: 10px;
	box-shadow: inset 0px 5px 8px rgba(0, 0, 0, 0.18);
}

.search {
	display: flex;
	width: calc(50vw - 25px);
	height: $topbar-height;
	float: right;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-right: 5px;
	background-color: transparent;
	border: transparent;
	padding: 0px 2px;
	padding-left: 10px;
	z-index: 20;
}

.control-divider{
	margin: 2px 0 2px 0;
	background: #B7C1DA;
	border: 1px solid #B7C1DA

}

.control-container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: auto;
	margin-left: 10px;
}

.control-container:hover {
	color: blue;
}

.topbar .control-container .label{
	display: flex;
	justify-content: center;
	align-items: center;
	//margin-top: 2px;
}

.label * {
	line-height: 0px;
}

.topbar .search {
	float: right;
	background-color: transparent;
	border: transparent;
	margin-left: auto;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
	max-width: 30px;
	max-height: 20px;
	background-color: transparent;
}

.control {
	color: $grey;
}

@media only screen and (max-width: 800px) {
	.search {
		width: 100%;
		min-width: 200px;
	}
}

@media only screen and (max-width: 650px) {

	.topbar {
		display: flex;
		flex-direction: column;
		max-height: calc($topbar-height);
		padding-right: 0px;
	}

	.search {
		width: 100%;
		padding: 0 15px;
	}

	.control-container {
		margin-left: 0;
	}

}

.table {
	padding: 20px;
	border-spacing: 0px;
    border-collapse: collapse;
	margin-top: 15px;
} 
th {
	background-color: $blue;
	color: white;
	min-width: 150px;
	padding: 10px;
}

td {
	border: 1px solid $light-blue;
	padding: 10px;

}

.results-panel {
	display: flex;
    flex-direction: column;
    align-items: center;
}


.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
    animation: ellipsis steps(4, end) 1500ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 15px;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 15px;
    }
  }
</style>
