<!-- HTML: This is Where You Put the HTML Code -->
<template>
	
	<modal v-model='isModalVisible'>
		<div class='property-not-found-container'>
			<div class='message'>
				<h6
				v-if="textContent['property_view']['not-found-part-one']"
				> {{ textContent['property_view']['not-found-part-one']['label'] }} </h6>
				<h6><strong> {{ this.propertyNotFound.value }} </strong></h6> 
				<h6
				v-if="textContent['property_view']['not-found-part-two']"
				> {{ textContent['property_view']['not-found-part-two']['label'] }} </h6>
			</div> 
			<div class='click-to-notify'>
				<p
				v-if="textContent['property_view']['not-found-notify-msg']"
				>  {{ textContent['property_view']['not-found-notify-msg']['label'] }} </p>
				<o-field v-if="textContent['property_view']['not-found-email-label']">
					<o-input 
						id="not-found-email-input"
						v-model='email'
						:placeholder="textContent['property_view']['not-found-email-label']['label']"
						size='medium'
						labelClass='btn-label'
					/>	
						<btn 
							variant="arkly" 
							style="{'height': '24px'}"
							outlined
							width='auto'
							v-on:click="postNotify"
						>
							{{textContent['property_view']['not-found-btn-submit']['label']}}
						</btn>

				</o-field>
			</div>
		</div>
	</modal>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapActions } from 'pinia'
import { useSurfStore } from '@/store/surf.js'
import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { useConfigStore } from '@/store/config'
import { event } from 'vue-gtag'

export default {
	components: {
		'btn': Button,
		'modal': Modal,
	},
	data() {
		return {

		}
	},
	computed: {
		isModalVisible: {
			get() {
				return this.propertyNotFound.isVisible
			},
			set(value) {
				//console.log(value)
				this.setPropertyNotFound(value)
			}
		},
		...mapState(useConfigStore, ['mapConfig','textContent']),
		...mapState(useSurfStore, ['propertyNotFound'])
	},
	created(){
		if (this.propertyNotFound.value){
			//console.log(this.propertyNotFound.value)
			if (this.propertyNotFound.value.includes('lng')){
				this.hideModal()
				if (this.propertyNotFound.value.includes('zoom')){
					this.mapConfig.zoomOverRide = this.propertyNotFound.value.split('zoom: ')[1]
					
				}
			}
		}
	},
	mounted() {
	},
	methods: {
		postNotify() {
			
			event('request_notify_property_not_found', {'event_category': 'search', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('request_notify_property_not_found', 'location ID: ' + window.location.href.split('/').slice(-1) + ', email: ' + this.email)
			this.requestToBeNotified(`Missing Property: ${this.propertyNotFound.value}` , this.email)
			this.hideModal()
		},
		hideModal() {
			this.isModalVisible = false
		},
		...mapActions(useSurfStore, ['setPropertyNotFound', 'requestToBeNotified']),
		...mapActions(useConfigStore, ['setStartingZoom'])
	},
	watch: {
		isModalVisible(newState) {

		},
		propertyNotFound: {
			handler: function(newState) {
				
				if (newState.value.includes('lng')){
					this.hideModal()
					const zoom = this.propertyNotFound.value.split('zoom:')[1]
					const lat = this.propertyNotFound.value.split(',')[0].split('lat:')[1]
					const lng = this.propertyNotFound.value.split(',')[1].split('lng:')[1]
					
					if (this.mapConfig.map){
						this.mapConfig.map.flyTo({
							duration: 10000,
							zoom: zoom,
							center: [lng,lat],
							essential: true // this animation is considered essential with respect to prefers-reduced-motion
						})
					}
				} else if (newState.lngLat) {
					if (this.mapConfig.map){
						this.mapConfig.map.flyTo({
							duration: 10000,
							zoom: this.mapConfig.map.getZoom(),
							center: [newState.lngLat[0],newState.lngLat[1]],
							essential: true // this animation is considered essential with respect to prefers-reduced-motion
						})
					}
				}
			},
			deep: true,
		}
	}
}
</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../style/arkly.scss";


.modal {
	position: absolute;
	width: 100vw;
	height:	100vh;
	z-index: 9999;
}

.property-not-found-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
	width: fit-content;
	max-width: 400px;
	height: auto;
	background-color: white;
	border-radius: $border-radius;
	padding: 15px 20px;
}

#close-modal {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-top: 5px;
	width: 100%;
}

.message {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.click-to-notify {
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

</style>
