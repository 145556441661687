<template>
<div 
	ref='legend' 
	class='arkly-legend-container' 
	:style="{width: isMobile ? 'fit-content' : 'auto', right: isMobile ? '10px' : '10px', bottom: isMobile ? '40px' : '30px'}" 
	:class="{'container-vertical': direction == 'vertical' }"
	v-if="$route.meta.platform == 'arkly'"
>
	<div class='feature'>
		<o-checkbox id="floodZoneCheckBox" v-model="isFloodZoneVisible" />
		<div class='fema' v-if='!isMobile' ref='fema'/>
		<h2> {{ textContent['property_view']['FEMA-flood-zone']['label'] }} </h2>
	</div>
	<div class='feature'>
		<o-checkbox id="AALCheckBox" v-model="isColorScaleVisible" />
		<div class='scale' v-if='!isMobile' ref='scale'>	
			<span class='color' v-for='color in colorScale.colors.slice()' >
				<div class='color' :style="{'background-color': color}"/>
			</span>
		</div>
		<h2> {{ textContent['property_view']['annual-flood-risk-legend']['label'] }} ($) </h2>
	</div>
</div>
<div 
	class='govsub-legend-container'
	:style="{'margin-bottom': isMobile ? '-15px' : '5px'}" 
	v-else
>
	<div class='legend-title'>
		<h4 v-if="!mapConfig.isEquityVisible"> 
			Damage From {{ controls.storm }} Flood in {{ controls.year }} ({{ controls.units }}) 
		</h4>
		<h4 v-else> 
			Households at Risk During <br>
			a {{ controls.storm }} Flood in {{ controls.year }}  
		</h4>
	</div>
	<div class='scale' ref='scale'>
		<div class='blank'/>
		<span class='label' v-for='label in colorScale.labels'>
			<div class='label'> {{label}} </div>
		</span>

		<span class='color' v-for='color in colorScale.colors'>
			<div class='color' :style="{'background-color': color}"/>
		</span>
	</div>
</div>
</template>

<script>
import chroma from 'chroma-js'
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import { event } from 'vue-gtag'

export default {
	components: {

	},
	props: [
		'map', 
		'layerLabel', 
		'units',
		'stormCategory',
		'year',
		'direction'
	],
	data() {
		return {
			zoom: 0,
			formattedColorScale: this.colorScale,
			update: false,
			legendPurpose: 'Property',
			stormCategoryLookup: {
				'category1': '1 Year',
				'category2': '5 Year',
				'category3': '10 Year',
				'category4': '50 Year',
				'category5': '100 Year',
			},
			scaleFactor: {
				'property': 1,
				'blockgroup': 10,
				'county': 10,
			},
			isColorScaleVisible: true,
			layerTypes: undefined,
		}
	},
	mounted() {
		this.zoom = this.map.getZoom()
		this.map.on('zoom', () => {
			this.zoom = this.map.getZoom()
		})
		this.layerTypes = Object.keys(this.mapConfig.layers);

		this.setFill()
		this.layerTypes.forEach(key => {
			if (key != 'floodZone'){
				this.mapConfig.layers[key].forEach(layer => {
					var pr_layer_not_in_pr = (!this.$route.name == 'puertoRico' && layer.includes('puertorico'))
					if (!pr_layer_not_in_pr) {
						this.map.setLayerZoomRange(layer, this.mapConfig.layerSettings[key].zoomRange[0], this.mapConfig.layerSettings[key].zoomRange[1])
					}
				})
			}
		})
		
		if (this.user && !this.mapConfig.arklyRoutes.includes(this.$route.name)) {
			const blockgroupAccess = this.user.access.blockgroups
			if (blockgroupAccess.length > 0){
				this.mapConfig.layers.blockgroup.forEach(layer => {
					this.map.setFilter(layer, ['in', this.mapConfig.blockgroupGovSubIdField].concat(blockgroupAccess))
				})
				this.mapConfig.layers.equity.forEach(layer => {
					this.map.setFilter(layer, ['in', this.mapConfig.equityGovSubIdField].concat(blockgroupAccess))
				})
				// Filter properties if they are in the matching blockgroups
				this.mapConfig.layers.property.forEach(layer => {
					this.map.setFilter(layer, ['in', 'blockgroup'].concat(blockgroupAccess))
				})
				
			}
			const countyAccess = this.user.access.counties 
			if (countyAccess.length > 0){
				// Filter any county that isn't in countyAccess
				this.mapConfig.layers.county.forEach(layer => {
					this.map.setFilter(layer, ['in', this.mapConfig.countyGovSubIdField].concat(countyAccess))
				})
				this.mapConfig.layers.blockgroup.forEach(layer => {
					this.map.setFilter(layer, ['in', ['slice', ['get', this.mapConfig.blockgroupGovSubIdField], 0, 6]].concat(countyAccess))
				})
				
				// arkly does not have an 'aggregate' layer
				if ('aggregate' in this.mapConfig.layers) {
					// Filter Aggregate (Blockgroup) if they are in counties matching countyAccess
					this.mapConfig.layers.aggregate.forEach(layer => {
						this.map.setFilter(layer, ['in', ['slice', ['get', 'key'], 0, 6]].concat(countyAccess))
					})
				}
				// Filter properties if they are in counties matching countyAccess
				var filterList = ["any"]
				countyAccess.forEach(currCounty => {
					filterList = filterList.concat([['in', ['slice', ['get', 'blockgroup'], 0, 6]].concat(currCounty)])
				})
				this.mapConfig.layers.property.forEach(layer => {
					this.map.setFilter(layer, filterList)
				})
				
			}
			
			const cityAccess = this.user.access.cities
			if (cityAccess.length > 0){
				if (blockgroupAccess.length > 0) {
					this.mapConfig.layers.property.forEach(layer => {
						this.map.setFilter(layer, ['all',['in', 'blockgroup'].concat(blockgroupAccess),['in', 'city'].concat(cityAccess)])
					})
				} else {
					this.mapConfig.layers.property.forEach(layer => {
						this.map.setFilter(layer, ['in', 'city'].concat(cityAccess))
					})
				}
				
			}
			
		}
		this.$emit('units', this.units)
		this.addComponentRef('legend', this.$refs.legend)

	},
	computed: {
		isFloodZoneVisible: {
			get() {
				return this.controls.isFloodZoneVisible
			},
			set(value) {
				this.setFloodZoneVisibility(value)
			}
		},
		division: function(){
			if (this.zoom <= this.mapConfig.layerSettings.blockgroup.zoomRange[0]) {
				this.legendPurpose = 'County Damage'
				this.currentUnits = '$'
				return 'county'
			} else if (this.zoom <= this.mapConfig.layerSettings.property.zoomRange[0]) {
				this.legendPurpose = 'Blockgroup Damage'
				return 'blockgroup'
			} else {
				this.legendPurpose = 'Property Damage'
				return 'property'
			}
		},
		equityColorScale: function() {
			// set steps for color scale
			const steps = this.fill.equity.steps	
		
			let labels = steps.map(label => {
				return this.abbreviateNumbers(label, 3)	
			})
			labels.unshift('0')
			
			let colors = chroma.scale('Reds').colors(steps.length)	
			
			return {'steps': steps, 'labels': labels, 'colors': colors, 'areaLabel': 'area'}
		},
		colorScale: function() {
			if (this.isEquityVisible) {
				return this.equityColorScale 
			} else {
				let currentColorScale = this.economicColorScale(this.mapConfig.layerSettings[this.division].colorScaleFactor)
				return currentColorScale
			}
		},
		fill() {
			return this.mapConfig.fill
		},
		...mapState(useConfigStore, ['isRouteLocation','mapboxLookupDict','textContent', 'mapConfig', 'controls', 'user', 'isMobile', 'mapSearchOptions']),
		...mapState(useSurfStore, ['equity']),
	},
	methods:  {
		...mapActions(useConfigStore, ['toggleEquityVisibility','addComponentRef', 'setMapSearchOptions', 'setFloodZoneVisibility']),
		economicColorScale: function(xFactor, layerName = 'florida') {
			// set steps for color scale
			let steps
			if (this.controls.units != "$"){
				steps = [0, 5, 10, 50, 100, 500]
				xFactor = 1
			} else {
				if (layerName.includes('puertorico')) {
					steps = [0, 500, 1000, 10000, 100000, 500000]
				} else {
					steps = [0, 10000, 100000, 500000, 1000000, 10000000]
				}
			}
			
			if (!xFactor) {
				xFactor = 1
			}
			if (xFactor != 1){
				steps = steps.map(step => step*xFactor)
			}

			let labels = steps.map(label => {
				return this.abbreviateNumbers(label, 3)	
			})
			
			let colors = chroma.scale('Reds').colors(steps.length-1)
			
			return {'steps': steps, 'labels': labels, 'colors': colors, 'areaLabel': 'area'}
		},
		abbreviateNumbers ( number, precision ) {

			//const max = this.max
			const abbrev = {'':1, 'K':1000, 'M':1000000, 'B':1000000000}
			const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
			const order = Math.max(0, Math.min(unrangifiedOrder, Object.keys(abbrev).length -1 ))
			const suffix = Object.keys(abbrev)[order]

			if ((number / Math.pow(10, order * 3)) == 0) {
				return 0
			} else {
				return Number((number / Math.pow(10, order * 3)).toPrecision(precision)) + suffix
			}	
		},
		fillColors: function(colorScale, layerType, layerName) {
			
			let equation, layerLabel
			//console.log(this.layerLabel, layerName)
			if (layerType == 'aggregate') {
				layerLabel = this.layerLabel.replace('expectedLoss_', '')
			} else if (layerName.includes('FZs')) {
				layerLabel = 'EL'
			} else if (this.$route.name == 'puertoRico' && !layerName.includes('florida')) {
				layerLabel = this.layerLabel.replace('expectedLoss_', 'AAL_').replace('_100year','')
			} else if (this.$route.name == 'puertoRico' && layerName.includes('florida')) {
				layerLabel = this.mapboxLookupDict[this.mapboxLookupDict[this.layerLabel].replace('AAL_', 'expectedLoss_').replace('_NOAA2022','_NOAA2022_100year')]
			} else if (layerName.includes('puertorico')) {
				layerLabel = this.mapboxLookupDict[this.layerLabel.replace('expectedLoss_', 'AAL_').replace('_100year','')]
			} else if (layerType != 'equity') {
				layerLabel = this.mapboxLookupDict[this.layerLabel]
			} else {
				layerLabel = this.layerLabel
			}
			
			if (this.controls.units != '$') {
				equation = ["/", ['get', layerLabel], ['get', colorScale.areaLabel]]
			} else {
				equation = ['get', layerLabel]
			}
			//if (layerType == 'property'){
			//console.log(layerType,layerName,layerLabel,this.mapboxLookupDict[this.layerLabel])
			//}
			let varNameForLastYearDamage
			if (this.$route.name == 'puertoRico') {
				varNameForLastYearDamage = 'damageInLastYear'
			} else {
				varNameForLastYearDamage = this.mapboxLookupDict[this.mapConfig.lastYearVariableName]
			}
			//console.log(varNameForLastYearDamage)
			var fill =  [
						'case',
						['==', ['get', varNameForLastYearDamage], 0],
						'white',
						[
						'step',
						equation,
						'rgba(255,255,255, 1)',
						colorScale.steps[0],
						colorScale.colors[0],
						colorScale.steps[1], 
						colorScale.colors[1],
						
						colorScale.steps[2], 
						colorScale.colors[2],
						
						colorScale.steps[3],
						colorScale.colors[3], 
						colorScale.steps[4],
						colorScale.colors[4],
						/*
						0,
						colorScale.colors[0],
						2000,
						colorScale.colors[4],
						*/
					]]
			
			//if (layerName.includes('FZs') && layerName.includes('properties') && (!this.layerLabel.includes('100year') || !this.layerLabel.includes('2020'))){
			//	fill = 'yellow'
			//	fill = 'black'
			//	fill = 'white'
				//fill = 'grey'
			//}
			return fill
		},
		searchOptionFillColors: function(options) {

			if (options) {
				const fill = [
					'case',
					[
						'in', 
						['get', 'structureId'], 
						['literal', this.mapSearchOptions]
					]		
				].concat(options)
			
				return fill
			} else {
				if (option == 'fill') {
					return this.fillColors(this.economicColorScale(1))
				} else {
					return ['match', ['get', 'damageInLastYear'], 0, 0.3, 1]
				}
			}

		},
		equityColors: function(colorScale) {
			const equation = ['get', this.layerLabel.replace('expectedLoss_','').replace('mean', 'atRisk')]	
			const fill =  [
						'interpolate',
						['linear'],
						equation,
						colorScale.steps[0],
						colorScale.colors[0],
						colorScale.steps[1], 
						colorScale.colors[1],
						colorScale.steps[2], 
						colorScale.colors[2],
						colorScale.steps[3],
						colorScale.colors[3], 
						colorScale.steps[4],
						colorScale.colors[4],
					]

			//console.log(fill)
			return fill
		},
		setFill() {	
			//console.log('set fill called',this.layerLabel)
			if ( 'equity' in this.mapConfig.layers) {
				this.map.setPaintProperty(this.mapConfig.layers.equity, 'fill-opacity', this.mapConfig.layerSettings.equity.opacity)
				this.map.setPaintProperty(this.mapConfig.layers.equity, 'fill-color', this.equityColors(this.equityColorScale))
				if (!this.isRouteLocation) {
					if (this.mapConfig.isEquityVisible) {
						this.toggleEquityVisibility(true)
					} else {
						this.map.setLayoutProperty(this.mapConfig.layers.equity, 'visibility', 'none')
					}
				} else {
					if (!this.mapConfig.isEquityVisible) {
							this.map.setLayoutProperty(this.mapConfig.layers.equity, 'visibility', 'none')
					}
				}
			}
			
				
			if (this.isColorScaleVisible) {
				var colorScaleVisible = 'visible'
			} else {
				var colorScaleVisible = 'none'
			}
			if (this.isFloodZoneVisible) {
				var floodZoneVisToUse = 'visible'
			} else {
				var floodZoneVisToUse = 'none'
			}
			this.layerTypes.forEach(key => {
				this.mapConfig.layers[key].forEach(layer => {
					var pr_layer_not_in_pr = (!this.$route.name == 'puertoRico' && layer.includes('puertorico'))
					//console.log(key,layer)
					if (key != 'equity' && !pr_layer_not_in_pr){
						if (this.mapConfig.isEquityVisible && key != 'floodZone') {
							this.map.setLayoutProperty(layer, 'visibility', 'none')
						} else {
							this.map.setLayoutProperty(layer, 'visibility', colorScaleVisible)
						}
						if (key == 'property') {
							if (this.mapSearchOptions && Array.isArray(this.mapSearchOptions) && this.mapSearchOptions.length == 0) {
								if (layer.includes('FZs')) {
									this.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacityFZs)
									//if (this.layerLabel.includes('100year') && this.layerLabel.includes('2020')){
									//	this.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacityFZs)
									//} else {
									//	this.map.setPaintProperty(layer, 'fill-opacity', 0.3)
									//}
								} else {
									if (this.$route.name == 'puertoRico') {
										this.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacity.PR)
									} else {
										this.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacity.FL)
									}
								}
								this.map.setPaintProperty(layer, 'fill-color', this.fillColors(this.economicColorScale(1,layer),'property',layer))
							} else {
								this.map.setPaintProperty(layer, 'fill-color', this.searchOptionFillColors(['white', 'white']))
								this.map.setPaintProperty(layer, 'fill-opacity', this.searchOptionFillColors([1,0.3]))

							}
						} else if (key == 'criticalAssets') {
							console.log('do nothing here for now')
						} else if (key != 'floodZone') {
							if (layer.includes('FZs')){
								if (!this.layerLabel.includes('100year') || !this.layerLabel.includes('2020')) {
									this.map.setPaintProperty(layer, 'fill-opacity', 0)
									this.map.setPaintProperty(layer + '_outline', 'line-opacity', 0)
								} else {
									this.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacity)
									this.map.setPaintProperty(layer + '_outline', 'line-opacity', this.mapConfig.layerSettings[key].opacity)
								} 
							}
							this.map.setPaintProperty(layer, 'fill-color', this.fillColors(this.economicColorScale(this.mapConfig.layerSettings[key].colorScaleFactor,layer), key, layer))
						} else if (key == 'floodZone') {
							this.map.setLayoutProperty(layer, 'visibility', floodZoneVisToUse)
						}
					}
				})
			})
			
		
			//this.map.setPaintProperty('PR-properties-v1', 'fill-color', ["interpolate",["linear"],["get", "abh"],63349,"#ffffff",475116,"#e60f0f"])
		},	
	},
	watch: {
		mapSearchOptions(newOptions) {
			//console.log('search options: ', newOptions)
		},
		isFloodZoneVisible(newValue) {
			event('floodzone_toggle', {'event_category': 'map', 'floodzoneToggle' : newValue, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('floodZone_toggle','location ID: ' + window.location.href.split('/').slice(-1) + ', flood zone toggle: ' + newValue)
			//console.log('flood zone fill call')
			this.setFill()
		},
		isColorScaleVisible(newVisible) {
			event('aal_toggle', {'event_category': 'map', 'aalToggle' : newVisible, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('AAL_toggle','location ID: ' + window.location.href.split('/').slice(-1) + ', AAL toggle: ' + newVisible)
			//console.log('color scale visible fill call')
			this.setFill()
		},
		division(newValue){
			if (!this.mapConfig.isEquityVisible) {
				event('map_division_' + newValue, {'event_category': 'map', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('map_division_' + newValue,'location ID: ' + window.location.href.split('/').slice(-1))
				//console.log('map division fill call: ' + newValue)
				this.setFill()
				this.$emit('mapDivision', newValue)
			}
		},
		/*
		colorScale: {
			handler: function(newValue){
				//this.setFill()
				this.$emit('handleScale', newValue)	
			},
			deep: true
		},
		*/
		layerLabel: function(newValue) {
			//console.log('layer label fill call')
			this.setFill()
		},
		units: {
			handler: function(newValue) {	
				//console.log('unit handler call')
				this.setFill()
			},
			deep: true
		},
		controls: {
			handler: function(currentControls){
				//console.log('controls watcher call')
				this.setFill()	
			},
			deep: true
		},
		$route: {
			handler: function(newRoute){
				//console.log('route watcher fill call')
				this.setFill()	
			},
			deep: true
		},
		isBlockgroup: function(newValue){
			
		},
	},
}
	

</script>

<style lang="scss" scoped> 
//@import "../style/mapbox.scss";
@import "./../../style/style.scss";
@import './../../../node_modules/mapbox-gl/dist/mapbox-gl.css';

.arkly-legend-container {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	height: auto;
	width: auto;
	background-color: rgba(255, 255, 255, 0.9); //rgba(5, 15, 41, 0.85);
	border-radius: 4px;
	padding: 6px;
	gap: 6px;
	z-index: 1;
}

.arkly-legend-container h2 {
	//-webkit-text-stroke: 0.5px white;
	font-size: 15px;
	font-family: $header-family !important;
	font-weight: bold !important;
	white-space: nowrap !important;
	color: rgb(44, 62, 80);//white !important;
	margin: 0;
	margin-right: 6px;
}

.govsub-legend-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: auto;
	height: auto;
	background-color: white;
	border-radius: 4px;
	padding: 3px;
	margin-right: 5px;
}

.govsub-legend-container > .govsub-legend-title >  h4 {
	display: flex;
	font-size: 15px;
	flex-align: column;
	align-items: center;
	justify-content: flex-start;
	grid-area: 1/2/1/-1;
	height: auto;
	width: auto;
	margin: 0;
	white-space: nowrap;
}


.container-vertical {
	flex-direction: column !important;	
}

.container-horizontal {
	flex-direction: row !important;	
}

.arkly-legend-title >  h4 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	grid-area: 1/2/1/-1;
	height: auto;
	width: auto;
	margin: 0;	
}

.arkly-legend-container > .feature {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-start;
	align-items: center;
	width: 100%;	
}

.arkly-legend-container > .feature > .fema {
	display: block;
	background-color: $primary;
	width: 50px !important;
	height: 20px;
	width: 100%;
	border: none;
	margin-right: 6px;
	padding: 0;
}

.container-vertical .legend-title {
	padding-top: 0;
}

.container-vertical .legend-title > h4 {
	font-weight: normal;
	font-size: 0.8rem;
}

.label .label {
	font-weight: normal;
	font-size: 0.8rem;
	margin-top: 4px;
}

.arkly-legend-title {
	font-family: $font-family;
	font-family: $header-family;
	font-weight: bold; 
	color: inherit;
	text-shadow: 0 0.5px 0 #585858;
	display: flex;
	font-size: 15px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 5px;
	padding-bottom: 0px;
}

.arkly-legend-container .scale {
	display: grid;
	grid-template-columns: repeat(10, 5px);
	grid-template-rows: auto 20px;
	grid-auto-flow: column !important;
	gap: 0 0;
	border: none;
	padding: 0;
	margin: 0;
	margin-right: 6px;
}

.govsub-legend-container .scale {
	display: grid;
	grid-template-columns: repeat(12, 20px);
	grid-template-rows: 20px 20px;
	gap: 0 0;
	border: none;
	padding: 5px;
	margin: 0;
}

.scale-vertical {
	grid-template-columns: auto auto 10px !important;
	grid-template-rows: repeat(12, 10px) !important;
	grid-gap: 4px;
}

.arkly-legend-container .blank {
	display: block;
	width:100%;
	grid-column: 1/2;
	grid-row: 3/4;
}

.govsub-legend-container .blank {
	display: block;
	width:100%;
	grid-column: 1/2;
	grid-row: 2/3;
}

.blank-vertical {
	grid-row: 1/2 !important;
	grid-column: 3/4 !important;
}

.scale .label {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 3px;
	grid-column-end: span 2;
	grid-row-start: 1;
	grid-row-end: 1;
}

.govsub-legend-container .scale .label {
	justify-content: center;
}

.scale .label-vertical {
	justify-content: flex-end;
	align-items: center;
	margin-top: 0;
	//margin-right: 3px;
	grid-row-start: auto !important;
	grid-row-end: span 2 !important;
	grid-column-start: 1 !important;
	grid-column-end: 1 !important;
}

.scale .label-vertical .marker {
	display: inline-block;
	width: 5px;
	//border-top: 2px solid black;
	margin-left: 0px;
}

.color {
	display: block; 
	width: 100%;
	height: 100%;
	grid-column-end: span 2 !important;
	grid-row-start: 2 !important;
	grid-row-end: 3 !important;
}

.color-vertical {
	//border-top: 0.5px solid black;
	//border-bottom: 0.5px solid black;
	grid-row-start: span 2 !important;
	grid-row-end: span 2 !important;
	grid-column-start: 3 !important;
	grid-column-end: 3 !important;
	border-radius: 4px;
}


</style>
