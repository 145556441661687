import decode from 'jwt-decode'
import axios from 'axios'
import FormData from 'form-data'
import { omit } from 'lodash'

const REST_ENDPOINT = 'https://dev-api.hightide.ai'
const AUTH_TOKEN_KEY = 'authToken'
const BLOCKGROUP_ACCESS = ''
const USER_INFO = ''
const BOUNDS = ''

export function loginUser(username, password) {	

    return new Promise(async (resolve, reject) => {
 			var data = new FormData()
			data.append('username', username)
			data.append('password', password)
				
			const instance = axios.create()
            instance.post(
                `${REST_ENDPOINT}/auth/login`,
				data,
				{
				headers: { "Content-Type": "multipart/form-data" }
				}
			).then(res => {	
				const instance2 = axios.create()
				const data2 = new FormData()
				
				instance2.get(
					`${REST_ENDPOINT}/auth/user`,
					{
						headers: { 'Authorization': `Bearer ${res.data.access_token}` }
					}
				).then( user => {
						setAuthToken(res.data.access_token)
						setUser(user['data'])
						setBlockgroupAccess(user['data']['access'])
						resolve()
				})	

			}).catch(error => {
				if (error.response) {
					reject(error.response.data.detail)
				} else if (error.request) {
					reject(error.request)
				} else {
					reject(error)
				}
				
			}) 
    })
}

export function logoutUser() {
	console.log('called logoutUser')
    clearAuthToken()
}

export function setAuthToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    localStorage.setItem('AUTH_TOKEN_KEY', token)
}

export function setBlockgroupAccess(access) {
    localStorage.setItem('BLOCKGROUP_ACCESS', JSON.stringify(access))
}

export function setUser(user) {
    localStorage.setItem('USER_INFO', JSON.stringify(user))
}

export function getAuthToken() {
	if (localStorage.getItem('AUTH_TOKEN_KEY')) {
		return `Bearer ${localStorage.getItem('AUTH_TOKEN_KEY')}`
	} else {
		return undefined 
	}
}

export function getBlockgroupAccess() {
    return JSON.parse(localStorage.getItem('BLOCKGROUP_ACCESS'))
}

export function getUser() {
    return JSON.parse(localStorage.getItem('USER_INFO'))
}

export function someFunc() {
	return localStorage.getItem('USER_INFO')
}

export function clearAuthToken() {
    axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem('AUTH_TOKEN_KEY')
	localStorage.removeItem('BLOCKGROUP_ACCESS')
	localStorage.removeItem('USER_INFO')
}

export function isLoggedIn() {
    let authToken = getAuthToken()
    return !!authToken && !isTokenExpired(authToken)
}

export function getUserInfo() {
	try { 
		if (isLoggedIn()) {
			return getUser() 
		} else {
			//console.error('Not Logged In')
		}
	}catch(e) {
		console.log(e)
	}
}

function getTokenExpirationDate(encodedToken) {
    let token = decode(encodedToken)
    if (!token.exp) {
        return null
    }
  
    let date = new Date(0)
    date.setUTCSeconds(token.exp)
  
    return date
}
  
function isTokenExpired(token) {
    let expirationDate = getTokenExpirationDate(token)
    return expirationDate < new Date()
}
