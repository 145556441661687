<template>
<div class='checkout-container'>
	<h4> Payment Failed </h4>
	<btn 
				label="explore-arkly-btn"
				id='explore-arkly-btn'
				variant="arkly"
				size='large' 
				width='auto'
				@click="goHome('failed_payment')"
			> Return to Arkly</btn>
</div>
</template>

<script>

import Button from '@/components/Button'
import { mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'

export default {
    name: 'FailedPayment',
	components: {
		'btn': Button,
	},
    data() {
        return {

		}
    },
	methods: {
	...mapActions(useConfigStore, ['goHome']),
	},
	watch: {
	},
}

</script>

<style>

.checkout-container{
	margin-top: 30vh !important;
	padding: 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 20px;
}

</style>
