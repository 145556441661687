<!-- HTML -->
<template>
<carousel ref='mitigate' @slideChanged='slideChanged'>
<div class='first page' :style="{width: isMobile ? '90vw' : '350px'}" id="mitigation_page1" v-if="textContent['mitigation']">
	
	<div style='width:100%' v-html="textContent['mitigation']['slide1_text1']['text_html']"></div>
	<div class='content'>
		<div class='price'>
			<h3> ${{ loanCost }} </h3>
			<h5> {{ textContent['buttons']['per-year']['label'] }} </h5>
		</div>

		<p v-html="textContent['mitigation']['slide1_text2']['text_html']"></p>

		<expert-consult 
			variant='arkly' 
			:title="textContent['buttons']['talk-to-an-expert']['label']"
			consultType='mitigation'
			icon='arrow-down-right'
			width='100%'
		/>
	</div>

</div>

<div class='page' id="mitigation_page2" v-if="textContent['mitigation']">
	<div class='info' v-html="slide2_content">
	</div>
	<div class='action'>
		<h5> {{ textContent['buttons']['mitigation-expert']['label'] }} </h5> 
		<expert-consult 
			variant='arkly' 
			title=''
			width='72px !important'
			consultType='mitigation'
			icon='arrow-down-right'
		/>
	</div>
</div>

<div class='page' id="mitigation_page3" v-if="textContent['mitigation']">
	<div class='info' v-html="textContent['mitigation']['slide3_text1']['text_html']">
	</div>
	<br>

	<div class='signUp'>
	<o-field class='signUp'>
		<o-input
			id="mitigation-email"
			autocomplete="email"
			v-model='email'
			:placeholder="textContent['buttons']['email-placeholder']['label']"
			size='medium'
			labelClass='btn-label'
			:height='24'
		/>	
	</o-field>
	<btn variant="arkly" label="mitigationEmailSubmit" outlined size='medium' v-on:click="request">{{ textContent['buttons']['submit-btn']['label'] }}</btn>
	</div>
</div>

<div class='page' id="mitigation_page4" v-if="textContent['mitigation']">
	<div class='info' v-html="textContent['mitigation']['slide4_text1']['text_html']">
	</div>
</div>

</carousel>

</template>

<!-- JavaScript -->
<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import Button from '@/components/Button'
import ExpertConsult from '@/components/arkly/ExpertConsult'
import Footer from '@/components/sidepanel/Footer'
import Carousel from '@/components/Carousel'
import { event } from 'vue-gtag'

export default {
	name: 'mitigation',
	components: {
		'btn': Button,
		'sidepanel-footer': Footer,
		'expert-consult': ExpertConsult,
		'carousel': Carousel,
	},
	data() {
		return {
			email: undefined,
		}
	},
	mounted() {
		this.addComponentRef('mitigate-your-risk', this.$refs.mitigate)
		
	},
	computed: {
		slide2_content() {
			var currContent = this.textContent['mitigation']['slide2_text1']['text_html']
			currContent = currContent.replace('lowValue',this.abbreviateNumbers(this.houseLiftingLoan.totalCost*0.9, 2, false))
			currContent = currContent.replace('highValue',this.abbreviateNumbers(this.houseLiftingLoan.totalCost*1.1, 2, false))
			currContent = currContent.replace('feetValue',this.houseLiftingLoan.elevationChange)
			return currContent
		},
		loanCost() {
			if (this.houseLiftingLoan) {
				return 	this.abbreviateNumbers(this.houseLiftingLoan.yearlyCost, 2, false)
			} else {
				return
			}
		},
		...mapState(useConfigStore, ['textContent']),
		...mapState(useSurfStore, ['coordinates', 'location', 'fullAddress', 'attributes', 'FFE', 'meanNFIP', 'meanNFIPstr', 'houseLiftingLoan', 'AAL', 'abbreviateNumbers','roundNumber', 'floodZone']),
		mitigationCost() {
			return '$' +  + " and $" + this.abbreviateNumbers(this.houseLiftingLoan.totalCost*1.1, 2) 
		},
	},
	methods: {
		slideChanged(newSlide) {
			event('mitigation_carousel', {'event_category': 'sidepanel', 'newPage': newSlide.id, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('mitigation_carousel','location ID: ' + window.location.href.split('/').slice(-1) + ', new page: ' + newSlide.id)
		},
		request() {
			event('mitigation_email', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('mitigation_email','location ID: ' + window.location.href.split('/').slice(-1) + ', email: ' + this.email)
			if (this.email) {
				this.requestToBeNotified(`Wishes to be Notified.` , this.email)
			}
		},
		closePanel(event) {
			this.$emit('closePanelContent') 
		},
		...mapActions(useSurfStore, ['abbreviateNumbers', 'requestToBeNotified','updateHouseLiftingLoan']),
		...mapActions(useConfigStore, ['addComponentRef']),	
	},
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../../style/arkly.scss";
@import "../../style/oruga.scss";

h1 {
	text-align: center;
	font-size: 30px;
	font-weight: bold;	
	margin: 20px 5px;
}

h2 {
	text-align: center;
	line-height: 1.4;
	margin: 20px 5px 10px;
}

p {
	margin-top: 10px;
}

.close-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: min-content;
	z-index: 999;
}

.page {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	text-align: left;
	padding: 30px;
	height: 100%;
	width: 100%;
}

.page .title {
	padding-top: 70px;
}

.page .btn-container {
	width: auto;
}

.first {
	background-color: $oat;
}

.page .content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 30px;
	width: 100%;
//	padding: 30px;
}

.page > .content > .price {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid black;
	width: 100%;
}

.page .action {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.signUp {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
}

.o-field {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	max-height: 80px;
	width: 100%;
}


/*
.mitigation-container {
	display: grid;
	height: 100%;
	max-width: 100%;
	width: 100%;
	grid-template-rows: min-content auto;
	grid-template-columns: 1fr;
}

.mitigation-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	max-width: 100%;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding-bottom: 20px;
}
*/

</style>

